import {
  BoltIcon,
  FlagIconBlue,
  LifebuoyIcon,
  PhotoIcon
} from 'common/components/Icons';
import { DataPlan } from 'forta-app/data/plans/types';

const plans: DataPlan[] = [
  {
    name: 'Scam Detector',
    description:
      'Provides real-time intelligence on scammers (EOAs, contracts and URLs) engaged in over 10 unique scam types including ice phishing, rug pulls and fraudulent NFT orders. Currently used by Web3 wallets, centralized exchanges, security tools and AML and Compliance platforms, among others.',
    learnMore: true,
    icon: FlagIconBlue,
    botId: '0x1d646c4045189991fdfd24a66b192a294158b839a6ec121d740474bdacb3ab23',
    link: '/scam-detector',
    author: 'Forta Community',
    attributes: [],
    price: {
      USDC: 899,
      FORT: 0
    },
    unlockAddresses: {
      USDC: '0x54089510aec879ca42e08bc4b166be8362b56bb2',
      FORT: '',
      trial: '0xe4610c8ac8e91978e6319fb1dd64b10d87a46450'
    },
    network: 137,
    skipRecipient: true,
    subscription_type: 'scam_feed'
  },
  {
    name: 'Attack Detector',
    description:
      'Detects smart contract attacks in real-time using a combination of heuristics and machine learning. This bot can detect attack activity before funds are stolen, giving teams and end users an opportunity to react before a loss occurs.',
    learnMore: true,
    icon: BoltIcon,
    botId: '0x80ed808b586aeebe9cdd4088ea4dea0a8e322909c0e4493c993e060e89c09ed1',
    link: '/attack-detector',
    author: 'Forta Community',
    attributes: [],
    price: {
      USDC: 399,
      FORT: 0
    },
    unlockAddresses: {
      USDC: '0x4Fd7019EaB30fF7347d215Fe1510d05e6FD420a4',
      FORT: '',
      trial: '0x4Af9b25297723F646eeF50cd1F2471ee0e5a97Cf'
    },
    network: 137,
    skipRecipient: true,
    subscription_type: 'attack_feed'
  },
  {
    name: 'Spam Detector',
    description:
      'Detects spam tokens that are airdropped to users without their consent. Some spam tokens are malicious, others are benign. This data feed can help wallets, marketplaces, AMMs and others detect and filter out unwanted tokens.',
    learnMore: true,
    icon: PhotoIcon,
    botId: '0xd45f7183783f5893f4b8e187746eaf7294f73a3bb966500d237bd0d5978673fa',
    link: '/spam-detector',
    author: 'Artem Kovalchuk',
    attributes: [],
    price: {
      USDC: 49,
      FORT: 0
    },
    unlockAddresses: {
      USDC: '0x6453379cbc14f7a277319b6c8b7577ae78524968',
      FORT: '',
      trial: '0xf84b294e47bcfd817d7d2b7a317d99afd01e4cc6'
    },
    network: 137,
    skipRecipient: true,
    subscription_type: 'spam_feed'
  },
  {
    name: 'Token Sniffer Rug Pull Detector',
    description:
      'This bot is an extension of Token Sniffer, the first and most-used token security platform, powered by Solidus Labs. Token Sniffer’s smart contract scanner analyzes token contract code and functionality, testing for malicious patterns to assess whether or not a token is a hard rug pull.',
    learnMore: true,
    icon: LifebuoyIcon,
    botId: '0x1ae0e0734a5d2b4ab26b8f63b5c323cceb8ecf9ac16d1276fcb399be0923567a',
    link: '/rug-pull-detector',
    author: 'Solidus Labs',
    attributes: [],
    price: {
      USDC: 899,
      FORT: 0
    },
    unlockAddresses: {
      USDC: '0x525d8d73f11a8586482f4f74c105d9d8b7cf73da',
      FORT: '',
      trial: '0xa7fd423969e923fc7c4d2ef315c0a14361a0b1c4'
    },
    network: 137,
    skipRecipient: true,
    subscription_type: 'rug_pull'
  },
  {
    name: 'General Data',
    description:
      'Access to 99%+ of the Network’s hundreds of detection bots (ie. everything except Premium Feeds). Discover and select which bots are best for you and get a customized experience.',
    // icon: FlagIconBlue,
    learnMore: true,
    link: '/bots',
    attributes: [
      {
        label: 'All community bots',
        checked: true
      },
      {
        label: 'No premium bots',
        checked: false
      }
    ],
    price: {
      USDC: 0,
      FORT: 250
    },
    unlockAddresses: {
      USDC: '',
      FORT: '0x4e0c80548e67a6e05464e540729222eb9812444d',
      trial: '0xb73d7cb1bb6340ade0bb3d7a2c396eb2551ad20d'
    },
    network: 137,
    additionalButton: {
      label: 'Explore all Intel Feeds',
      onClick: () => (window.location.href = '/bots')
    },
    skipRecipient: true,
    subscription_type: 'general'
  }
];

export const trials: string[] = [
  '0xb73d7cb1bb6340ade0bb3d7a2c396eb2551ad20d',
  '0xe4610c8ac8e91978e6319fb1dd64b10d87a46450',
  '0xf84b294e47bcfd817d7d2b7a317d99afd01e4cc6',
  '0xa7fd423969e923fc7c4d2ef315c0a14361a0b1c4',
  '0x4Af9b25297723F646eeF50cd1F2471ee0e5a97Cf'
];

export default plans;
