import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAgents } from 'common/lib/apis/agentAPI';

import { RootState } from 'forta-app/app/store';
import {
  Notification,
  getNotificationList,
  NotificationEndpointType,
  Email,
  getEmails,
  BotGroup,
  getBotGroups,
  NotificationFilter,
  Filter,
  FilterRule,
  SubscriptionType
} from 'forta-app/lib/apis/notificationAPI';

export type NotificationType = 'KIT' | 'BOT' | 'ADDRESS';

export const AlertFilterOptions = [
  'severity',
  'consensus',
  'chain',
  'alertId',
  'addresses'
] as const;

export type AlertFilterOption = typeof AlertFilterOptions[number];

export const AlertSeverities = [
  'critical',
  'high',
  'medium',
  'low',
  'info'
] as const;

export type AlertSeverity = typeof AlertSeverities[number];

export const DEFAULT_BOT_ASSIGNED_SCANNER_COUNT = 6;

export interface NotificationModalState {
  id: number;
  opened: boolean;
  loading: boolean;
  notificationType: NotificationType;
  groupId: string;
  filter: NotificationModalFilterState;
  sources: string[];
  endpointType: NotificationEndpointType;
  endpoint: string;
}

export interface NotificationModalFilterState {
  alertId?: string;
  chainId?: string;
  minimumSeverity?: AlertSeverity;
  minimumScannerConfirmations?: {
    value?: string;
    valueType: 'percentage' | 'number';
  };
  addresses?: string[];
  botIds?: string[];
}

export interface NotificationSliceState {
  modal: NotificationModalState;
  emails: Email[];
  notifications: Notification[];
  notificationsLoading: boolean;
  botGroupsLoading: boolean;
  botGroups: BotGroup[];
}

const initialState: NotificationSliceState = {
  modal: {
    id: -1,
    opened: false,
    loading: false,
    notificationType: 'BOT',
    groupId: '',
    filter: {},
    sources: [''],
    endpointType: 'SLACK',
    endpoint: ''
  },
  emails: [],
  notifications: [],
  notificationsLoading: false,
  botGroupsLoading: false,
  botGroups: []
};

export const retrieveNotifications = createAsyncThunk<
  { notifications: Notification[]; emails: Email[] },
  { jwt: string },
  { state: RootState }
>('notification/retrieveNotifications', async (params) => {
  const notifications = await getNotificationList(params);
  const emails = await getEmails(params);
  const notificationsWithNames = await Promise.all(
    notifications.map(async (notification) => {
      if (notification.source.type === 'AGENT') {
        try {
          const agents = await getAgents({ ids: [notification.source.value] });
          return {
            ...notification,
            source: {
              ...notification.source,
              name: agents[0].name,
              subscription_type: agents[0].subscription_type as SubscriptionType
            }
          };
        } catch (e) {
          console.error(e);
          return notification;
        }
      } else {
        return notification;
      }
    })
  );
  return { notifications: notificationsWithNames, emails };
});

export const retrieveBotGroups = createAsyncThunk<
  { botGroups: BotGroup[] },
  void,
  { state: RootState }
>('notification/retriveBotGroups', async () => {
  const botGroups = (await getBotGroups()) || [];
  return { botGroups };
});

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setModalState: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.modal = {
        ...state.modal,
        ...action.payload
      };
    },
    closeModal: (state) => {
      state.modal = {
        id: -1,
        opened: false,
        loading: false,
        notificationType: 'BOT',
        groupId:
          state.modal.groupId ||
          (state.botGroups.length ? state.botGroups[0].id : ''),
        sources: [''],
        endpointType: 'SLACK',
        filter: {},
        endpoint: ''
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNotifications.pending, (state) => {
        state.notificationsLoading = true;
      })
      .addCase(retrieveNotifications.rejected, (state) => {
        state.notificationsLoading = false;
        state.notifications = [];
      })
      .addCase(retrieveNotifications.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        state.notifications = action.payload.notifications;
        state.emails = action.payload.emails;
      })
      .addCase(retrieveBotGroups.pending, (state) => {
        state.botGroupsLoading = true;
      })
      .addCase(retrieveBotGroups.rejected, (state) => {
        state.botGroupsLoading = false;
        state.notifications = [];
      })
      .addCase(retrieveBotGroups.fulfilled, (state, action) => {
        state.botGroupsLoading = false;
        state.botGroups = action.payload.botGroups;
      });
  }
});

export const { setModalState, closeModal } = notificationSlice.actions;

export const getInclusionFiltersFromState = (
  filter: NotificationModalFilterState,
  notificationType: NotificationType,
  sources: string[]
): NotificationFilter => {
  const rules: FilterRule[] = [];

  if (filter['minimumSeverity']) {
    rules.push({
      field: 'severity',
      operator: 'greaterThanOrEquals',
      value: filter['minimumSeverity'],
      type: 'field'
    });
  }

  if (filter['minimumScannerConfirmations']) {
    const valueType = filter['minimumScannerConfirmations'].valueType;
    const value = filter['minimumScannerConfirmations'].value;

    if (valueType === 'number' && value) {
      rules.push({
        field: 'confirmations',
        operator: 'greaterThanOrEquals',
        value,
        type: 'field'
      });
    } else if (valueType === 'percentage' && value) {
      rules.push({
        field: 'confirmationPercentage',
        operator: 'greaterThanOrEquals',
        value,
        type: 'field'
      });
    }
  }

  if (filter['alertId']) {
    rules.push({
      field: 'alertId',
      operator: 'equals',
      value: filter['alertId'],
      type: 'field'
    });
  }

  if (filter['chainId']) {
    rules.push({
      field: 'chainId',
      operator: 'equals',
      value: filter['chainId'],
      type: 'field'
    });
  }

  if (filter['addresses']) {
    rules.push({
      field: 'addresses',
      operator: 'containsAnyIgnoreCase',
      value: filter['addresses'].join(','),
      type: 'field'
    });
  }

  const includeFilter: Filter[] = [];

  if (notificationType !== 'ADDRESS') {
    rules
      ? includeFilter.push({ matchType: 'all', rules, botId: sources[0] })
      : {};
  } else {
    // Subscription to an address allows for multiple bots to filter on
    if (filter.botIds) {
      filter.botIds.forEach((botId) =>
        rules
          ? includeFilter.push({ matchType: 'all', rules, botId: botId })
          : {}
      );
    }
  }

  return {
    includeFilters: includeFilter ? includeFilter : [],
    excludeFilters: []
  };
};

export const getModalFilterFromState = (
  notification: Notification
): NotificationModalFilterState => {
  const modalFilters: NotificationModalFilterState = {};
  const includeFilters = notification?.filter?.includeFilters;

  if (includeFilters) {
    includeFilters.forEach((filter) => {
      const rules = filter.rules;

      rules.forEach((rule) => {
        const field = rule.field;
        const value = rule.value;

        if (field === 'severity') {
          modalFilters.minimumSeverity = value as AlertSeverity;
        }

        if (field === 'confirmations') {
          modalFilters.minimumScannerConfirmations = {
            value,
            valueType: 'number'
          };
        }

        if (field === 'confirmationPercentage') {
          modalFilters.minimumScannerConfirmations = {
            value,
            valueType: 'percentage'
          };
        }

        if (field === 'chainId') {
          modalFilters.chainId = value;
        }
        if (field === 'alertId') {
          modalFilters.alertId = value;
        }

        if (field === 'addresses') {
          modalFilters.addresses = value.split(',');
        }
      });

      // Assuming if there are multiple filters then the subscription if filtering multiple botIds
      if (includeFilters.length > 0 && filter.botId) {
        modalFilters.botIds
          ? modalFilters.botIds.push(filter.botId)
          : (modalFilters.botIds = [filter.botId]);
      }
    });
  }

  return modalFilters;
};

export default notificationSlice.reducer;
