import { NetworksInfo } from 'common/lib/networks';
import { Severities, Alert } from 'common/lib/apis/alertAPI';

type SortFunction = (a: Alert, b: Alert) => number;

const SortStrategies: { [key: string]: SortFunction } = {
  date: sortByDate,
  // type: sortByType,
  network: sortByNetwork,
  severity: sortBySeverity,
  'date-inverse': inverse(sortByDate),
  // "type-inverse": inverse(sortByType),
  'network-inverse': inverse(sortByNetwork),
  'severity-inverse': inverse(sortBySeverity)
};

function inverse(f: SortFunction): SortFunction {
  return (a: Alert, b: Alert) => f(b, a);
}

function sortByDate(a: Alert, b: Alert): number {
  if (a.source.block.timestamp > b.source.block.timestamp) return 1;
  if (a.source.block.timestamp < b.source.block.timestamp) return -1;
  return 0;
}
/*
function sortByType(a: Alert, b: Alert): number {
  if (a.type > b.alert.type) return 1;
  if (a.alert.type < b.alert.type) return -1;
  return 0;
}*/

function sortByNetwork(a: Alert, b: Alert): number {
  const _a = NetworksInfo[a.network].chainId;
  const _b = NetworksInfo[b.network].chainId;
  if (_a > _b) return 1;
  if (_a < _b) return -1;
  return 0;
}

function sortBySeverity(a: Alert, b: Alert): number {
  const _a = Severities.indexOf(a.severity);
  const _b = Severities.indexOf(b.severity);
  if (_a > _b) return 1;
  if (_a < _b) return -1;
  return 0;
}

export default SortStrategies;
