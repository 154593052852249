export const currentENV = (() => {
  switch (process.env.CONTEXT) {
    case 'production':
      return 'production';
    case 'local':
      return 'local';
    default:
      return 'staging';
  }
})();

export interface ConfigEnvType {
  apiEndpoint: string;
  subgraphAPIEndpoint: string;
  unlockSubgraphAPIEndpoint: string;
  agentRegistryContractAddress: string;
  stakingContractAddress: string;
  FORTContractAddress: string;
  dispatchContractAddress: string;
  scannerPoolRegistryAddress: string;
  rewardsDistributorAddress: string;
  stakeAllocatorAddress: string;
  appEndpoint: string;
  explorerEndpoint: string;
  wizardEndpoint: string;
  infuraEndpoint: string;
  walletConnectProjectId: string;
  infuraId: string;
  chainExplorer: string;
  chainLabel: string;
  chainNativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  chainId: number;
}

export const configs: {
  local: ConfigEnvType;
  staging: ConfigEnvType;
  production: ConfigEnvType;
} = {
  local: {
    apiEndpoint:
      process.env.EXPLORER_API ||
      'https://explorer-api-dev.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://polygon-mumbai.graph-eu.p2pify.com/6dbdbeb3262ca5fa43773df1e690bd53/forta-dev',
    unlockSubgraphAPIEndpoint:
      'https://api.thegraph.com/subgraphs/name/unlock-protocol/mumbai-v2',
    appEndpoint: 'https://app-dev.forta.network',
    explorerEndpoint: 'https://explorer-dev.forta.network',
    wizardEndpoint: 'https://wizard-dev.forta.network',
    agentRegistryContractAddress: '0x4519Eaeb9A5c1a9d870Fe35452E54AD04E6e8428',
    stakingContractAddress: '0x64d5192F03bD98dB1De2AA8B4abAC5419eaC32CE',
    FORTContractAddress: '0x107ac13567b1b5d84691f890a5ba07edae1a11c3',
    scannerPoolRegistryAddress: '0x9BBEBf9CB94666464D8a5978363B4943D36A05E4',
    rewardsDistributorAddress: '0x404afc59Cacd74A28d0D5651460Cc950b42FAf08',
    stakeAllocatorAddress: '0x698A6400dE3865fC9E8A6dB22302AAb3372Cebd9',
    dispatchContractAddress: '0x4B71C61400F5da35518DE10dEcd21D1Aa4d7f283',
    infuraEndpoint:
      'https://polygon-mumbai.infura.io/v3/2a27d3959a754066b2923fad03079ed1',
    walletConnectProjectId: '07bc5ac193e5170f13ed9eac02af80f3',
    infuraId: '2a27d3959a754066b2923fad03079ed1',
    chainExplorer: 'https://mumbai.polygonscan.com/',
    chainLabel: 'Mumbai',
    chainNativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    chainId: 80001
  },
  staging: {
    apiEndpoint: 'https://explorer-api-dev.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://polygon-mumbai.graph-eu.p2pify.com/6dbdbeb3262ca5fa43773df1e690bd53/forta-dev',
    unlockSubgraphAPIEndpoint:
      'https://api.thegraph.com/subgraphs/name/unlock-protocol/mumbai-v2',
    appEndpoint: 'https://app-dev.forta.network',
    explorerEndpoint: 'https://explorer-dev.forta.network',
    wizardEndpoint: 'https://wizard-dev.forta.network',
    agentRegistryContractAddress: '0x4519Eaeb9A5c1a9d870Fe35452E54AD04E6e8428',
    stakingContractAddress: '0x64d5192F03bD98dB1De2AA8B4abAC5419eaC32CE',
    FORTContractAddress: '0x107ac13567b1b5d84691f890a5ba07edae1a11c3',
    scannerPoolRegistryAddress: '0x9BBEBf9CB94666464D8a5978363B4943D36A05E4',
    rewardsDistributorAddress: '0x404afc59Cacd74A28d0D5651460Cc950b42FAf08',
    stakeAllocatorAddress: '0x698A6400dE3865fC9E8A6dB22302AAb3372Cebd9',
    dispatchContractAddress: '0x4B71C61400F5da35518DE10dEcd21D1Aa4d7f283',
    infuraEndpoint:
      'https://polygon-mumbai.infura.io/v3/2a27d3959a754066b2923fad03079ed1',
    walletConnectProjectId: '07bc5ac193e5170f13ed9eac02af80f3',
    infuraId: '2a27d3959a754066b2923fad03079ed1',
    chainExplorer: 'https://mumbai.polygonscan.com/',
    chainLabel: 'Mumbai',
    chainNativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    chainId: 80001
  },
  production: {
    apiEndpoint: 'https://explorer-api.forta.network/graphql',
    subgraphAPIEndpoint:
      'https://api.thegraph.com/subgraphs/name/forta-network/forta-network',
    unlockSubgraphAPIEndpoint:
      'https://api.thegraph.com/subgraphs/name/unlock-protocol/polygon-v2',
    appEndpoint: 'https://app.forta.network',
    explorerEndpoint: 'https://explorer.forta.network',
    wizardEndpoint: 'https://wizard.forta.network',
    agentRegistryContractAddress: '0x61447385B019187daa48e91c55c02AF1F1f3F863',
    stakingContractAddress: '0xd2863157539b1d11f39ce23fc4834b62082f6874',
    FORTContractAddress: '0x9ff62d1fc52a907b6dcba8077c2ddca6e6a9d3e1',
    scannerPoolRegistryAddress: '0x90ff9c193d6714e0e7a923b2bd481fb73fec731d',
    rewardsDistributorAddress: '0xf7239f26b79145297737166b0c66f4919af9c507',
    stakeAllocatorAddress: '0x5B73756e637A77Fa52e5Ce71EC6189A4C775c6FA',
    dispatchContractAddress: '0xd46832F3f8EA8bDEFe5316696c0364F01b31a573',
    infuraEndpoint:
      'https://polygon-mainnet.infura.io/v3/7e3ca1fd616247b696df2b802852b6b2',
    walletConnectProjectId: 'f5f36d453ad86cebcba25654de7fd527',
    infuraId: '7e3ca1fd616247b696df2b802852b6b2',
    chainExplorer: 'https://polygonscan.com/',
    chainLabel: 'Polygon',
    chainNativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    chainId: 137
  }
};

export default configs[currentENV];
