import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { TransactionResponse } from '@ethersproject/providers';
import { toast } from 'react-toastify';

import './BotMenuCell.scss';

import AgentRegistry from 'forta-app/lib/contract-interactors/agentRegistry';
import ActionCell from 'common/components-v2/Table/ActionCell';
import { AgentDeploymentContent } from 'forta-app/pages/AgentDeploymentPage';
import Modal from 'common/components/Modal';
import { Agent } from 'common/lib/apis/agentAPI';
import { hasRequiredBotStake } from 'forta-app/lib/utils';

export enum AgentUpdateType {
  EDIT,
  ENABLE,
  DISABLE
}
export interface AgentUpdate {
  agentId: string;
  type: AgentUpdateType;
  date: number;
  txHash: string;
}

export type AgentUpdateCallback = (
  agentUpdate: AgentUpdate,
  tx: TransactionResponse
) => void;

export default function BotMenuCell({
  agent,
  stake,
  onUpdate,
  disabled
}: {
  agent: Agent;
  stake: number;
  disabled: boolean;
  onUpdate: AgentUpdateCallback;
}): JSX.Element {
  const history = useHistory();

  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const web3React = useWeb3React();

  const handleEditClick = (): void => {
    setEditModalOpened(true);
  };

  const handleEditDeploy = (date: number, tx: TransactionResponse): void => {
    setEditModalOpened(false);
    onUpdate(
      {
        agentId: agent.id,
        type: AgentUpdateType.EDIT,
        date,
        txHash: tx.hash
      },
      tx
    );
  };

  const handleDisableClick = async (): Promise<void> => {
    try {
      const registry = new AgentRegistry(web3React);
      const date = Date.now();
      const tx = await registry.disableAgent(agent.id);
      onUpdate(
        {
          agentId: agent.id,
          type: AgentUpdateType.DISABLE,
          date,
          txHash: tx.hash
        },
        tx
      );
    } catch (error) {
      toast.error(`Error trying to disable bot`);
      if (error?.code === -32603 || error?.message === 'Internal error') {
        toast.warn(`Your RPC endpoint may not be working correctly.`);
      }
    }
  };

  const handleEnableClick = async (): Promise<void> => {
    try {
      const registry = new AgentRegistry(web3React);
      const date = Date.now();
      const tx = await registry.enableAgent(agent.id);
      onUpdate(
        {
          agentId: agent.id,
          type: AgentUpdateType.ENABLE,
          date,
          txHash: tx.hash
        },
        tx
      );
    } catch (error) {
      toast.error(`Error trying to disable bot`);
      if (error?.code === -32603 || error?.message === 'Internal error') {
        toast.warn(`Your RPC endpoint may not be working correctly.`);
      }
    }
  };

  return (
    <>
      <ActionCell
        disabled={disabled}
        options={[
          {
            label: 'Edit',
            onClick: handleEditClick
          },
          {
            label: 'Disable',
            onClick: handleDisableClick,
            disabled: !agent.enabled
          },
          {
            label: 'Enable',
            onClick: handleEnableClick,
            disabled: agent.enabled || !hasRequiredBotStake(stake)
          },
          {
            label: 'Stake',
            onClick: () =>
              history.push(
                `/profile/${web3React.account}/staking?stake_subject=${agent.id}&stake_subject_type=1`
              )
          }
        ]}
      />
      <Modal
        opened={editModalOpened}
        title="Update bot information"
        onCloseModal={() => setEditModalOpened(false)}
        className="BotMenuCell__modal"
      >
        <AgentDeploymentContent agent={agent} onDeploy={handleEditDeploy} />
      </Modal>
    </>
  );
}
