import React, { useEffect, useMemo, useState } from 'react';
import { BigNumber, providers } from 'ethers';
import { formatEther } from 'ethers/lib/utils';

import './ProfileDelegations.scss';

import Button from 'common/components-v2/Button/Button';
import Loader from 'common/components/Loader';
import Link from 'common/components-v2/Button/Link';
import DelegationsTable, {
  ScannerPoolDelegation
} from 'forta-app/components/delegations/DelegationsTable';
import DelegationsChart from 'forta-app/components/delegations/DelegationsChart';
import StakeModal from 'forta-app/components/staking/StakingModal';
import WithdrawModal from 'forta-app/components/staking/WithdrawModal';
import TabIntro from 'forta-app/components/TabIntro';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';
import useOffsetPagination from 'common/hooks/useOffsetPagination';
import { AccountStakeQueryData } from 'common/hooks/useAccountStakeQuery';
import { Plus2Icon } from 'common/components/Icons';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { formatNumber } from 'forta-app/lib/utils';
import { useQueryParam } from 'use-query-params';
import { SortingParam } from 'common/lib/query-params';

type ProfileDelegationsProps = {
  stakeQuery: AccountStakeQueryData;
};

const TABLE_EL_ID = 'delegations-table';

function ProfileDelegationsPage(props: ProfileDelegationsProps): JSX.Element {
  const { stakeQuery } = props;

  const {
    stake,
    fetched: isStakeFetched,
    refetching: isStakeRefetching,
    refetch: refetchStake
  } = stakeQuery;

  const [scannerPoolToDelegate, setScannerPoolToDelegate] =
    useState<ScannerPoolDelegation | null>(null);
  const [scannerPoolToUnDelegate, setScannerPoolToUnDelegate] =
    useState<ScannerPoolDelegation | null>(null);

  const scannerPoolsPagination = useOffsetPagination(20, {
    scrollToElementId: TABLE_EL_ID
  });
  const [sortingParam] = useQueryParam('sorting', SortingParam);

  const sorting = sortingParam || {
    orderBy: 'apyForLastEpoch',
    orderDirection: 'desc'
  };

  const {
    scannerPools,
    fetched: isScannerPoolsFetched,
    refetching: isScannerPoolsRefetching,
    loading: isScannerPoolsFetching,
    hasNextPage: hasScannerPoolsNextPage,
    refetch: refetchScannerPools
  } = useScannerPoolsWithApyQuery({
    params: {
      skip: scannerPoolsPagination.offset.skip,
      first: scannerPoolsPagination.offset.first,
      id_in: stake?.delegations.map((d) => d.subject.subjectId) || [],
      orderBy: sorting.orderBy,
      orderDirection: sorting.orderDirection
    },
    enabled: isStakeFetched
  });

  const scannerPoolDelegations = useMemo(() => {
    if (!stake || !scannerPools.length) return [];

    const delegations: ScannerPoolDelegation[] = [];
    for (const pool of scannerPools) {
      const delegationStake = stake.delegations.find(
        (p) => p.subject.subjectId === pool.id
      );

      if (!delegationStake) {
        console.error(
          `Something went wrong, we cannot find stake data for the pool ID: ${pool.id}`,
          { pool, stake }
        );
        continue;
      }

      delegations.push({
        ...pool,
        stake: delegationStake
      });
    }

    return delegations;
  }, [scannerPools, stake]);

  useEffect(() => {
    if (!stakeQuery.loading && !stakeQuery.refetching && stakeQuery.data) {
      stakeQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseModal(tx?: providers.TransactionResponse): void {
    if (tx) {
      refetchStake();
      refetchScannerPools();
    }
    setScannerPoolToDelegate(null);
    setScannerPoolToUnDelegate(null);
  }

  function handleDelegate(delegation: ScannerPoolDelegation): void {
    setScannerPoolToDelegate(delegation);
  }

  function handleUnDelegate(delegation: ScannerPoolDelegation): void {
    setScannerPoolToUnDelegate(delegation);
  }

  const isInitializing = !stake && !isStakeFetched;
  const isEmpty = !stake || stake.history.delegations.length === 0;

  if (isInitializing) {
    return (
      <div className="ProfileDelegations">
        <div className="ProfileDelegations__loader">
          <Loader />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="ProfileDelegations">
        <TabIntro>
          <TabIntro.Content>
            <TabIntro.Title>
              Start earning rewards with Forta Staking
            </TabIntro.Title>
            <TabIntro.Description>
              Become a delegator to help secure the Forta network and earn
              rewards.
              <br />
              <Link
                href="https://docs.forta.network/en/latest/delegated-staking-introduction/"
                target="_blank"
              >
                Learn more about the process here.
              </Link>
            </TabIntro.Description>
            <p>
              <Button
                variant="primary"
                size="lg"
                to="/pools"
                disabled={isStakeRefetching}
                loading={isStakeRefetching}
              >
                Become a Delegator
              </Button>
            </p>
          </TabIntro.Content>
        </TabIntro>
      </div>
    );
  }

  return (
    <div className="ProfileDelegations">
      <div className="ProfileDelegations__header">
        <div>
          <h3 className="ProfileDelegations__title">Delegations</h3>
          <p className="ProfileDelegations__description">
            You have delegated
            <span className="ProfileDelegations__delegated-tokens">
              {formatNumber(formatEther(stake.totalDelegationsStake))}
            </span>
            FORT
          </p>
        </div>
        <Button size="md" variant="primary" startIcon={Plus2Icon} to="/pools">
          Delegate
        </Button>
      </div>
      <DelegationsChart
        stake={stake}
        loading={isStakeRefetching || !stake}
        className="ProfileDelegations__section"
      />
      <DelegationsTable
        id={TABLE_EL_ID}
        loading={
          isScannerPoolsRefetching ||
          isScannerPoolsFetching ||
          !isScannerPoolsFetched
        }
        empty={scannerPoolDelegations.length === 0}
        scannerPools={scannerPoolDelegations}
        pagination={{
          ...scannerPoolsPagination,
          hasNextPage: hasScannerPoolsNextPage
        }}
        onDelegate={handleDelegate}
        onUnDelegate={handleUnDelegate}
        className="ProfileDelegations__section"
      />
      <StakeModal
        opened={!!scannerPoolToDelegate}
        subjectId={scannerPoolToDelegate?.id}
        subjectType={SubjectType.SCANNERPOOL_DELEGATOR}
        delegatedForm={
          scannerPoolToDelegate
            ? {
                pool: scannerPoolToDelegate,
                commision: scannerPoolToDelegate.commission,
                owner: scannerPoolToDelegate.owner.id,
                allocation: {
                  stakeAllocated: BigNumber.from(
                    scannerPoolToDelegate.stakeAllocated
                  ),
                  stakeDelegated: BigNumber.from(
                    scannerPoolToDelegate.stakeDelegated
                  ),
                  stakeOwned: BigNumber.from(scannerPoolToDelegate.stakeOwned),
                  stakeOwnedAllocated: BigNumber.from(
                    scannerPoolToDelegate.stakeOwnedAllocated
                  )
                }
              }
            : undefined
        }
        onClose={handleCloseModal}
      />
      <WithdrawModal
        opened={!!scannerPoolToUnDelegate}
        stake={scannerPoolToUnDelegate?.stake}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default ProfileDelegationsPage;
