import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-loading-skeleton/dist/skeleton.css';

import ApolloClientProvider from 'common/components/ApolloClientProvider';
import NotFoundPage from 'common/pages/NotFoundPage';
import Header from 'forta-explorer/components/Header';
import Footer from 'common/components/Footer';

import 'common/styles/App.scss';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'common/components-v2/ScrollToTop/ScrollToTop';
import config from 'common/config';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { Routes } from '../../common/routes';

Modal.setAppElement('#root');

type AppRedirectProps = {
  to?: string;
};

function AppRedirect(props: AppRedirectProps): JSX.Element {
  const { to } = props;

  useEffect(() => {
    window.location.href = to
      ? config.appEndpoint + to
      : `${config.appEndpoint}${window.location.pathname}${window.location.search}`;
  }, [to]);

  return <div>Redirecting...</div>;
}

function App(): JSX.Element {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      }),
    []
  );

  return (
    <ApolloClientProvider>
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme baseColor="#424448" highlightColor="#575757">
          <Router>
            <Helmet>
              <title>Forta Explorer</title>
              <meta
                name="description"
                content="Explore alerts on the Forta Network."
              />
            </Helmet>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <div className="App">
                <Header />
                <div className="App__body">
                  <div className="Content">
                    <ScrollToTop>
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={() => <AppRedirect to={'/bots'} />}
                        />
                        <Route
                          exact
                          path="/explorer"
                          render={() => (
                            <AppRedirect to={Routes.alerts.index({})} />
                          )}
                        />
                        <Route
                          exact
                          path="/alerts"
                          render={() => (
                            <AppRedirect to={Routes.alerts.index({})} />
                          )}
                        />
                        <Route
                          exact
                          path="/alerts/:id"
                          render={({ match }) => (
                            <AppRedirect
                              to={Routes.alerts.details.index({
                                id: match.params.id
                              })}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/alert/:id"
                          render={({ match }) => (
                            <AppRedirect
                              to={Routes.alerts.details.index({
                                id: match.params.id
                              })}
                            />
                          )}
                        />
                        <Redirect exact path="/agent/:id" to="/bot/:id" />
                        <Route exact path="/bot/:id" component={AppRedirect} />
                        <Route exact path="/network" component={AppRedirect} />
                        <Route
                          exact
                          path="/scan-node/:id"
                          component={AppRedirect}
                        />
                        <Route
                          exact
                          path="/page-not-found"
                          component={NotFoundPage}
                        />
                        <Route component={NotFoundPage} />
                      </Switch>
                    </ScrollToTop>
                  </div>
                </div>
                <Footer />
              </div>
            </QueryParamProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="dark"
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </SkeletonTheme>
      </QueryClientProvider>
    </ApolloClientProvider>
  );
}

export default App;
