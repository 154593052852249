import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { range } from 'lodash';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import './BotOverview.scss';
import useSubjectStake from '../useSubjectStake';
import useBotStats from '../useBotStats';

import { DAY_TIME, shortenHash } from 'common/lib/utils';
import CopyButton from 'common/components/CopyButton';
import {
  BoltColorIcon,
  ExternalLinkIcon,
  PuzzleColorIcon
} from 'common/components/Icons';
import { formatFORT } from 'forta-app/lib/utils';
import { getNetworkByChainId } from 'common/lib/networks';
import { Agent } from 'common/lib/apis/agentAPI';
import ChainIcon from 'common/components/stats/ChainIcon';
import InfoPopover from 'common/components/InfoPopover';
import Calendar, { DayValue } from 'common/components/stats/Calendar';
import { Pie, PieDataItem } from 'common/components/stats/Pie';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { getAlertStats, SeveritiesInfo } from 'common/lib/apis/alertAPI';
import Counter from 'common/components/stats/Counter';
import { MetricInterval } from 'forta-app/data/metrics';
import BotAlerts from '../Alerts/BotAlerts';

type MetadataItemProps = {
  label: string;
  value: string | number | JSX.Element;
  link?: string;
};

function MetadataItem(props: MetadataItemProps): JSX.Element {
  const { label, value, link } = props;

  return (
    <li className="MetadataItem">
      <div className="MetadataItem__label">{label}</div>
      <div className="MetadataItem__value">
        {value}
        {link && (
          <a href={link} target="_blank" rel="noreferrer">
            {ExternalLinkIcon}
          </a>
        )}
      </div>
    </li>
  );
}

type BotMetadataProps = {
  bot: Agent;
  className?: string;
};

function BotMetadata(props: BotMetadataProps): JSX.Element {
  const { bot, className } = props;

  const { stakeSubject } = useSubjectStake({
    params: {
      botId: bot?.id || '',
      subjectType: SubjectType.BOT
    },
    enabled: !!bot
  });

  return (
    <section className={cn('BotMetadata', className)}>
      <h3 className="BotMetadata__title">Bot Information</h3>
      <ul className="BotMetadata__list">
        <MetadataItem
          label="Bot ID"
          value={<CopyButton text={bot.id}>{shortenHash(bot.id)}</CopyButton>}
        />
        <MetadataItem
          label="Developer"
          value={
            <CopyButton text={bot.developer}>
              {shortenHash(bot.developer)}
            </CopyButton>
          }
        />
        <MetadataItem
          label="Image"
          link={`https://ipfs.forta.network/ipfs/${bot.manifest_ipfs}`}
          value={
            bot.image ? (
              <CopyButton text={bot.image}>{shortenHash(bot.image)}</CopyButton>
            ) : (
              '-'
            )
          }
        />
        <MetadataItem
          label="Created"
          value={moment(bot.created_at).fromNow()}
        />
        <MetadataItem
          label="Last Updated"
          value={moment(bot.updated_at).fromNow()}
        />
        <MetadataItem
          label="Active Stake"
          value={
            stakeSubject ? (
              `${formatFORT(stakeSubject.activeStake)} FORT`
            ) : (
              <Skeleton width={70} />
            )
          }
        />
      </ul>
    </section>
  );
}

type BotNetworkInfoProps = {
  bot: Agent;
  className?: string;
};

function BotNetworkInfo(props: BotNetworkInfoProps): JSX.Element {
  const { bot, className } = props;

  return (
    <section className={cn('BotNetworkInfo', className)}>
      <h3 className="BotNetworkInfo__title">Networks scanned</h3>
      <ul className="BotNetworkInfo__networks">
        {bot && bot.chainIds
          ? bot.chainIds.map((chainId) => {
              const network = getNetworkByChainId(Number(chainId));
              return (
                <li
                  className="BotNetworkInfo__network"
                  key={`network-${network.chainId}`}
                >
                  <ChainIcon
                    network={network}
                    className="BotNetworkInfo__network-icon"
                  />
                  {network.label}
                </li>
              );
            })
          : range(5).map((i) => <Skeleton key={i} width={70} height={24} />)}
      </ul>
      <h3 className="BotNetworkInfo__title BotNetworkInfo__title--operators">
        Node operators
      </h3>
      <div>{bot.scanners?.length ?? 0}</div>
    </section>
  );
}

type FancyCounterProps = {
  label: string;
  value: string | number;
  period: string;
  icon: JSX.Element;
  tooltip: string;
  className?: string;
};

function FancyCounter(props: FancyCounterProps): JSX.Element {
  const { label, value, tooltip, period, icon, className } = props;

  return (
    <div className={cn('FancyCounter', className)}>
      <div className="FancyCounter__header">
        <div className="FancyCounter__icon">{icon}</div>
        <div className="FancyCounter__label">{label}</div>
        <div className="FancyCounter__info">
          <InfoPopover content={tooltip} />
        </div>
      </div>
      <div className="FancyCounter__body">
        <div className="FancyCounter__value">
          <Counter
            value={Number(value)}
            decimals={0}
            duration={2}
            className="FancyCounter__number"
          />
        </div>
        <div className="FancyCounter__period">{period}</div>
      </div>
    </div>
  );
}

type BotOverviewProps = {
  bot?: Agent;
};

function BotOverview(props: BotOverviewProps): JSX.Element {
  const { bot } = props;

  // const dispatch = useAppDispatch();
  const [dayIntervalAlerts, setDayIntervalAlerts] = useState<DayValue[]>([]);
  const [severityAlerts, setSeverityAlerts] = useState<PieDataItem[]>([]);

  const { stats } = useBotStats({
    botId: bot?.id,
    timeframe: MetricInterval.Day,
    enabled: !!bot
  });

  const toDateDay = (utcDate: number): string =>
    moment(utcDate).format('YYYY-MM-DD');

  const startDate = Date.now() - DAY_TIME * 180;
  const endDate = Date.now();

  useEffect(() => {
    if (!bot) return;

    const fetchData = async (): Promise<void> => {
      try {
        const alertStats = await getAlertStats({
          severity: [],
          agents: [bot.id],
          txHash: '',
          text: '',
          muted: [],
          sort: '',
          limit: 0,
          project: '',
          startDate: startDate.toString(),
          endDate: endDate.toString(),
          aggregations: {
            severity: true,
            interval: 'day',
            alerts: 6,
            cardinalities: true
          }
        });
        const severities = alertStats.severity || [];
        setDayIntervalAlerts(
          alertStats.interval?.map((item) => ({
            value: item.doc_count,
            day: toDateDay(Number(item.key))
          })) || []
        );
        const total = severities.reduce(
          (result, item) => result + item.doc_count,
          0
        );
        setSeverityAlerts(
          severities.map((severity) => {
            const severityInfo = SeveritiesInfo[severity.key] || {};
            const percentage = Math.floor((100 * severity.doc_count) / total);
            return {
              id: severity.key,
              value: severity.doc_count,
              label: `${severityInfo.label} ${percentage || '<1'}%`,
              color: severityInfo.color
            };
          })
        );
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot]);

  if (!bot) {
    return (
      <div className="BotOverview">
        <div className="BotOverview__info">
          <div className="BotOverview__metadata">
            <Skeleton height={254} borderRadius={10} />
          </div>
          <div className="BotOverview__network">
            <Skeleton height={254} borderRadius={10} />
          </div>
          <div className="BotOverview__counters">
            <Skeleton height={120} borderRadius={10} />
            <Skeleton height={120} borderRadius={10} />
          </div>
        </div>
        <div className="BotOverview__charts">
          <div className="BotOverview__calendar-chart">
            <Skeleton height={254} borderRadius={10} />
          </div>
          <div className="BotOverview__severity-chart">
            <Skeleton height={254} borderRadius={10} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="BotOverview">
      <div className="BotOverview__info">
        <BotMetadata bot={bot} className="BotOverview__metadata" />
        <BotNetworkInfo bot={bot} className="BotOverview__network" />
        <div className="BotOverview__counters">
          <FancyCounter
            label="Alerts"
            value={stats?.alertCount ?? 0}
            period="24h"
            tooltip="Alerts are notifications that an observed behavior has occurred."
            icon={BoltColorIcon}
          />
          <FancyCounter
            label="Labels"
            value={stats?.labelCount ?? 0}
            period="24h"
            tooltip="Labels are persistent tags identifying specific entities."
            icon={PuzzleColorIcon}
          />
        </div>
      </div>
      <div className="BotOverview__charts">
        <div className="BotOverviewChart BotOverview__calendar-chart">
          <div className="BotOverviewChart__title">
            Alerts per day (this is a stacked graph)
          </div>
          <div className="BotOverviewChart__body">
            <Calendar
              from={toDateDay(startDate)}
              to={toDateDay(endDate)}
              data={dayIntervalAlerts}
              valueLabel="Alerts"
            />
          </div>
        </div>
        <div className="BotOverviewChart BotOverview__severity-chart">
          <div className="BotOverviewChart__title">Alert Severities</div>
          <div className="BotOverviewChart__body">
            {severityAlerts.length ? (
              <Pie data={severityAlerts} />
            ) : (
              <div className="BotOverviewChart__empty-state">No alerts</div>
            )}
          </div>
        </div>
      </div>
      <BotAlerts botId={bot.id} />
    </div>
  );
}

export default BotOverview;
