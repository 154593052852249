import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import React, { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString from 'query-string';

import { store } from 'forta-app/app/store';
import ApolloClientProvider from 'common/components/ApolloClientProvider';
import Header from 'forta-app/components/Header';
import Footer from 'common/components/Footer';
import GetStartedPage from 'forta-app/pages/GetStartedPage';
import ProjectPage from 'forta-app/pages/ProjectPage';
import NotFoundPage from 'common/pages/NotFoundPage';
import DiscoverPage from 'forta-app/pages/DiscoverPage';
import OldHomePage from 'forta-app/pages/OldHomePage';
import NotificationModal from 'forta-app/components/notifications/NotificationModal';
import AgentDeploymentPage from 'forta-app/pages/AgentDeploymentPage';
import ScannerPoolPage from 'forta-app/pages/ScannerPoolPage';
import BotPage from 'forta-app/pages/bot/BotPage';
import NotificationsPage from './NotificationsPage';

import 'react-toastify/dist/ReactToastify.css';
import 'common/styles/App.scss';
import { WizardPage } from './WizardPage';
import ProfilePage from './ProfilePage';
import { DelegationPage } from './delegation/DelegationPage';
import { Routes } from 'common/routes';
import { Web3ContextProvider } from 'forta-app/components/wallet/Web3ContextProvider';
import { ConnectModalProvider } from 'forta-app/components/wallet/useConnectModal';
import { DeleteNotificationModalProvider } from 'forta-app/components/notifications/DeleteNotification';
import plans from 'forta-app/data/plans';
import AlertsPage from './alerts/AlertsPage';
import AlertPage from './alerts/id/AlertPage';
import BotSearchPage from './bot/BotSearchPage';
import NetworkPage from './NetworkPage';
import ScanNodePage from './ScanNodePage';

Modal.setAppElement('#root');

function App(): JSX.Element {
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        }
      }),
    []
  );

  return (
    <Web3ContextProvider>
      <Provider store={store}>
        <ApolloClientProvider>
          <QueryClientProvider client={queryClient}>
            <ConnectModalProvider>
              <DeleteNotificationModalProvider>
                <SkeletonTheme baseColor="#424448" highlightColor="#575757">
                  <Router>
                    <QueryParamProvider
                      adapter={ReactRouter5Adapter}
                      options={{
                        searchStringToObject: queryString.parse,
                        objectToSearchString: queryString.stringify
                      }}
                    >
                      <Helmet>
                        <title>Forta App</title>
                        <meta
                          name="description"
                          content="Manage and subscribe to Forta bots."
                        />
                      </Helmet>
                      <div id="FortaApp" className="App">
                        <Header />
                        <div className="App__body">
                          <div className="Content">
                            <Switch>
                              <Route
                                exact
                                path="/"
                                component={GetStartedPage}
                              />
                              <Route
                                exact
                                path={Routes.alerts.indexPath}
                                component={AlertsPage}
                              />
                              <Route
                                exact
                                path={Routes.alerts.details.indexPath}
                                component={AlertPage}
                              />
                              <Route
                                exact
                                path="/tdk"
                                component={OldHomePage}
                              />
                              <Redirect exact path="/agents" to="/" />
                              <Route
                                exact
                                path="/agents/:projectId"
                                component={ProjectPage}
                              />
                              <Route
                                exact
                                path="/deploy-agent"
                                component={AgentDeploymentPage}
                              />
                              <Route
                                exact
                                path="/wizard"
                                component={WizardPage}
                              />
                              <Route
                                exact
                                path="/discover"
                                component={DiscoverPage}
                              />
                              <Route
                                exact
                                path="/scan-node/:id"
                                component={ScanNodePage}
                              />
                              <Route
                                path="/nodePool/:id"
                                component={ScannerPoolPage}
                              />
                              <Route path="/bots" component={BotSearchPage} />
                              <Route
                                path={Routes.bot.detailsPath}
                                component={BotPage}
                              />
                              <Route
                                path="/profile/:address"
                                component={ProfilePage}
                              />
                              <Route path="/pools" component={DelegationPage} />
                              <Route path="/network" component={NetworkPage} />
                              <Route
                                path="/notifications"
                                component={NotificationsPage}
                              />
                              {plans.map(
                                (plan) =>
                                  plan.link &&
                                  plan.link !== '/' && (
                                    <Route
                                      path={plan.link}
                                      component={BotPage}
                                    />
                                  )
                              )}
                              <Route component={NotFoundPage} />
                            </Switch>
                          </div>
                        </div>
                        <Footer />
                      </div>
                      <ToastContainer
                        position="bottom-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        theme="dark"
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      <NotificationModal />
                    </QueryParamProvider>
                  </Router>
                </SkeletonTheme>
              </DeleteNotificationModalProvider>
            </ConnectModalProvider>
          </QueryClientProvider>
        </ApolloClientProvider>
      </Provider>
    </Web3ContextProvider>
  );
}

export default App;
