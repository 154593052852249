import React, { Ref, useRef } from 'react';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import './Fade.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NodeElement = any;

type FadeProps = {
  visible?: boolean;
  className?: string;
  children: (ref: Ref<NodeElement>) => JSX.Element;
};

function Fade({ visible, className, children }: FadeProps): JSX.Element {
  const nodeRef = useRef<NodeElement>();
  return (
    <CSSTransition
      unmountOnExit
      nodeRef={nodeRef}
      in={visible}
      timeout={300}
      classNames={cn('FadeTransition', className)}
    >
      {children(nodeRef)}
    </CSSTransition>
  );
}

export default Fade;
