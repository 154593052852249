import chroma from 'chroma-js';
import isIPFS from 'is-ipfs';
import { useState } from 'react';
import { networks } from 'common/lib/networks';
import CopyButton from 'common/components/CopyButton';
import FileSelector from 'common/components/FileSelector';
import InfoPopover from 'common/components/InfoPopover';
import Input from 'common/components/Input';
import SelectFilter from 'common/components/SelectFilter';
import { BranchIcon } from 'common/components/Icons';
import './AgentForm.scss';
import ProjectSelector from './ProjectSelector';
import { getRepositoryHref, shortenHash } from 'common/lib/utils';
import Button from 'common/components-v2/Button/Button';
import Checkbox from 'common/components-v2/Form/Checkbox/Checkbox';

const SIZE_1_MB = 1048576;

// TODO Update form elements to "components v2" to make design consistent
// TODO Refactor with yap & formik

export interface AgentFormData {
  id: string;
  name: string;
  description: string;
  longDescription: string;
  promoUrl: string;
  licenseUrl: string;
  selectedNetworks: number[];
  projects: string[];
  version: string;
  repository: string;
  image: string;
  documentation: File | null;
  documentationHash: string | null;
  manifest: Manifest | null;
  manifestSignature: string;
  external: boolean;
}

export interface Manifest {
  agentId: string;
  from: string;
  name: string;
  description?: string;
  longDescription?: string;
  agentIdHash: string;
  projects: string[];
  version: string;
  timestamp: string;
  promoUrl?: string;
  licenseUrl?: string;
  imageReference: string;
  documentation: string;
  repository?: string;
  chainIds: number[];
  publishedFrom: string;
  external: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const blockchainStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? 'white'
        : isFocused
        ? '#27272a80'
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : isFocused
        ? 'white'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color)
      }
    };
  },
  multiValue: (styles: any, { data }: any) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color,
      cursor: 'pointer'
    };
  },
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    backgroundColor: data.color,
    borderRadius: '50px 0 0 50px',
    color: 'white',
    fontWeight: '600',
    paddingLeft: '10px'
  }),
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: 'white',
    backgroundColor: data.color,
    borderRadius: '0 50px 50px 0',
    paddingRight: '8px',
    marginRight: '2px',
    ':hover': {
      color: '#ccc',
      cursor: 'pointer'
    }
  })
};

// https://stackoverflow.com/a/5717133
const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
);

const validations = {
  validateImage(image: string): boolean {
    if (image.length <= 0) return false;
    const imageRegex = RegExp(/^[a-z0-9]{59}@sha256:[a-f0-9]{64}$/);
    if (!image.match(imageRegex)) return false;
    if (!isIPFS.base32cid(image.substring(0, image.indexOf('@sha256:')))) {
      return false;
    }
    return true;
  },
  validateVersion(version: string): boolean {
    if (version?.length <= 0) return false;
    const validCharRegex = RegExp(/([0-9A-Za-z.]+)/);
    const validCharRes = validCharRegex.exec(version);
    if (!validCharRes || validCharRes[0] !== version) {
      return false;
    }
    return true;
  },
  validateRepository(repository: string): boolean {
    if (repository.length === 0) return true;
    if (!getRepositoryHref(repository)) return false;
    return true;
  },
  validateName(name: string): boolean {
    if (name.length <= 60) return true;
    const validCharRegex = RegExp(/([0-9A-Za-zÀ-ÖØ-öø-ÿ_. -]+)/);
    const validCharRes = validCharRegex.exec(name);
    if (!validCharRes || validCharRes[0] !== name) {
      return false;
    }
    return true;
  },
  validateDescription(description: string): boolean {
    return description.length <= 100;
  },
  validateLongDescription(description: string): boolean {
    return description.length <= 1000;
  },
  validateLicenseUrl(url: string): boolean {
    return url.length === 0 || (urlRegex.test(url) && url.length < 1000);
  },
  validatePromoUrl(url: string): boolean {
    return url.length === 0 || (urlRegex.test(url) && url.length < 1000);
  }
};

export default function AgentForm({
  formData,
  onChange,
  onSubmit
}: {
  onChange: (formData: AgentFormData) => void;
  onSubmit: () => void;
  formData: AgentFormData;
}): JSX.Element {
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [dockerDisabled, setDockerDisabled] = useState<boolean>(false);

  const onSignClick = (): void => {
    const errors: Record<string, boolean> = {
      name: !validations.validateName(formData.name),
      description: !validations.validateDescription(formData.description),
      validateLongDescription: !validations.validateLongDescription(
        formData.longDescription
      ),
      licenseUrl: !validations.validateLicenseUrl(formData.licenseUrl),
      promoUrl: !validations.validatePromoUrl(formData.promoUrl),
      selectedNetworks: !formData.selectedNetworks.length,
      version: !validations.validateVersion(formData.version),
      image: !formData.external && !validations.validateImage(formData.image),
      repository: !validations.validateRepository(formData.repository),
      documentation:
        !formData.documentationHash &&
        (!formData.documentation || formData.documentation.size > SIZE_1_MB)
    };

    setErrors(errors);

    if (!Object.values(errors).some((value) => value)) {
      onSubmit();
    }
  };

  const onClearClick = (): void => {
    onChange({
      id: formData.id,
      name: '',
      description: '',
      longDescription: '',
      licenseUrl: '',
      promoUrl: '',
      selectedNetworks: [],
      projects: [],
      version: '',
      repository: '',
      image: '',
      documentation: null,
      documentationHash: null,
      manifest: null,
      manifestSignature: '',
      external: false
    });
  };

  const handleExternalBotCheck = (): void => {
    formData.external = !formData.external;
    setDockerDisabled(formData.external);
    errors.image = false;
  };

  return (
    <div className="AgentForm">
      <div className="AgentForm__field">
        <div className="AgentForm__label">ID (auto generated)</div>
        <div className="AgentForm__id">
          {formData.id} <CopyButton text={formData.id} />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Name your detection bot <span className="asterisk">*</span>
        </div>
        <div className="AgentForm__input">
          <Input
            placeholder="Your bot's name"
            name="agent-deployment-name"
            value={formData.name}
            errored={errors.name}
            subtext={errors.name ? 'Invalid bot name' : undefined}
            onChange={(value) => onChange({ ...formData, name: value })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Short description</div>
        <div className="AgentForm__input">
          <Input
            placeholder="Short description (up to 100 characters)"
            name="agent-deployment-name"
            value={formData.description}
            errored={errors.description}
            subtext={errors.description ? 'Too long description' : undefined}
            onChange={(description) => onChange({ ...formData, description })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Long description</div>
        <div className="AgentForm__input">
          <Input
            textarea
            placeholder="Longer description, key metrics, highlights & selling points (eg. precision, recall, protected value, etc.)"
            name="agent-deployment-name"
            value={formData.longDescription}
            errored={errors.longDescription}
            rows={5}
            subtext={
              errors.longDescription ? 'Too long description' : undefined
            }
            onChange={(longDescription) =>
              onChange({ ...formData, longDescription })
            }
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Covered blockchains <span className="asterisk">*</span>
        </div>
        <div className="AgentForm__input">
          <SelectFilter
            options={networks}
            placeholder="Select blockchains to watch"
            isSearchable={false}
            onSelect={(value) =>
              onChange({
                ...formData,
                selectedNetworks: value.map((item) => Number(item))
              })
            }
            defaultValue={formData.selectedNetworks
              .map((chainId) =>
                networks.find((_network) => chainId === _network.chainId)
              )
              .filter((_) => _)}
            errored={errors.selectedNetworks}
            subtext={
              errors.selectedNetworks
                ? 'At least one network required'
                : undefined
            }
            icon={BranchIcon}
            styles={blockchainStyles}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Projects{' '}
          <InfoPopover content="Projects from ethereum-lists Github's repository" />
        </div>
        <div className="AgentForm__input">
          <ProjectSelector
            onChange={(projects) => onChange({ ...formData, projects })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">Promo link</div>
        <div className="AgentForm__input">
          <Input
            placeholder="A doc or page showcasing how the bot works, performance, developer info, or more"
            name="agent-deployment-name"
            value={formData.promoUrl}
            errored={errors.promoUrl}
            subtext={errors.promoUrl ? 'Not a valid url' : undefined}
            onChange={(promoUrl) => onChange({ ...formData, promoUrl })}
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">License</div>
        <div className="AgentForm__input">
          <Input
            placeholder="Link to license text"
            name="agent-deployment-name"
            value={formData.licenseUrl}
            errored={errors.licenseUrl}
            subtext={errors.licenseUrl ? 'Not a valid url' : undefined}
            onChange={(licenseUrl) => onChange({ ...formData, licenseUrl })}
          />
        </div>
      </div>
      <div className="AgentForm__field-group">
        <div className="AgentForm__field AgentForm__field-version">
          <div className="AgentForm__label">
            Version <span className="asterisk">*</span>
          </div>
          <div className="AgentForm__input">
            <Input
              placeholder="0.0.1"
              name="agent-deployment-version"
              value={formData.version}
              errored={errors.version}
              subtext={errors.version ? 'Invalid format' : undefined}
              onChange={(value) => onChange({ ...formData, version: value })}
            />
          </div>
        </div>
        <div className="AgentForm__field AgentForm__field-repository">
          <div className="AgentForm__label">Repository</div>
          <div className="AgentForm__input">
            <Input
              placeholder="GitHub or GitLab repository URL..."
              name="agent-deployment-repository"
              value={formData.repository}
              errored={errors.repository}
              subtext={
                errors.repository
                  ? 'Only GitHub or GitLab repository HTTPS URLs'
                  : undefined
              }
              onChange={(value) => onChange({ ...formData, repository: value })}
            />
          </div>
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Docker Image <span className="asterisk">*</span>{' '}
          <div className="AgentForm__input">
            <Input
              placeholder="Add an image hash"
              name="agent-deployment-image"
              value={formData.image}
              errored={errors.image}
              subtext={errors.image ? 'Invalid image format' : undefined}
              onChange={(value) => onChange({ ...formData, image: value })}
              disabled={dockerDisabled}
            />
          </div>
        </div>
        <div className="AgentForm__checkbox">
          <Checkbox
            label="External Bot"
            checked={formData.external}
            onChange={handleExternalBotCheck}
          />
          <InfoPopover
            content="Docker Image is not required for an External Bot. Learn more about External Bots in the docs."
            className="AgentForm__checkbox-tooltip"
            onClick={() =>
              window.open('https://docs.forta.network/external-bots', '_blank')
            }
          />
        </div>
      </div>
      <div className="AgentForm__field">
        <div className="AgentForm__label">
          Documentation <span className="asterisk">*</span>
        </div>
        <div className="AgentForm__file">
          <FileSelector
            errored={errors.documentation}
            subtext={
              errors.documentation
                ? 'File empty or too large (max 1MB).'
                : undefined
            }
            onChange={(file) =>
              onChange({
                ...formData,
                documentation: file,
                documentationHash: ''
              })
            }
            name={
              formData.documentation
                ? formData.documentation.name
                : formData.documentationHash
                ? shortenHash(formData.documentationHash)
                : ''
            }
          />
        </div>
      </div>
      <div className="actions">
        <Button
          variant="tertiary"
          size="sm"
          className="button-cancel"
          onClick={onClearClick}
        >
          Clear
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="button-submit"
          onClick={onSignClick}
        >
          Sign to proceed
        </Button>
      </div>
    </div>
  );
}
