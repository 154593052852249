import { useState } from 'react';
import {
  ApolloError,
  ApolloQueryResult,
  gql,
  NetworkStatus,
  OperationVariables,
  QueryResult,
  useQuery
} from '@apollo/client';

import { Agent, AgentListApiResponse } from 'common/lib/apis/agentAPI';

export const BOTS_QUERY = gql`
  query RetrieveBots($getAgentInput: AgentListInput) {
    getAgentList(input: $getAgentInput) {
      hasNextPage
      agents {
        id
        name
        developer
        agent_id
        created_at
        updated_at
        version
        description
        long_description
        chainIds
        promo_url
        license_url
        repository
        alerts
        enabled
        scanners
        subscription_type
        manifest_ipfs
        doc_ipfs
        image
      }
    }
  }
`;

export type BotsQueryParams = {
  id?: string;
  ids?: string[];
  text?: string;
  enabled?: boolean;
  developer?: string;
  pageSize?: number;
  pageNumber?: number;
};

const DEFAULT_PAGE_SIZE = 20;

function useBotsQuery(opts: { params: BotsQueryParams }): {
  bots: Agent[];
  data: { getAgentList: AgentListApiResponse } | undefined;
  fetchMore: (page: number) => Promise<void>;
  queryResult: QueryResult<{ getAgentList: AgentListApiResponse }>;
  refetch: (variables?: Partial<OperationVariables>) => Promise<
    ApolloQueryResult<{
      getAgentList: AgentListApiResponse;
    }>
  >;
  invalidate: () => Promise<void>;
  error: ApolloError | undefined;
  loading: boolean;
  refetching: boolean;
  hasNextPage: boolean;
  isFetchingMore: boolean;
} {
  const { params = {} } = opts || {};

  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const pageNumber = params.pageNumber ?? 0;
  const pageSize = params.pageSize ?? DEFAULT_PAGE_SIZE;

  const queryResult = useQuery<{ getAgentList: AgentListApiResponse }>(
    BOTS_QUERY,
    {
      variables: {
        getAgentInput: {
          ...params,
          pageNumber,
          pageSize
        }
      },
      notifyOnNetworkStatusChange: true
    }
  );

  const { loading, error, networkStatus, refetch } = queryResult;
  const data = queryResult.data;
  const bots = data?.getAgentList.agents || [];

  async function invalidate(): Promise<void> {
    await queryResult.client.clearStore();
  }

  async function fetchMore(page: number): Promise<void> {
    try {
      setIsFetchingMore(true);
      await queryResult.fetchMore({
        variables: {
          getAgentInput: {
            ...params,
            pageNumber: page
          }
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          return {
            ...fetchMoreResult,
            getAgentList: {
              ...fetchMoreResult?.getAgentList,
              agents: [
                ...(previousQueryResult?.getAgentList?.agents || []),
                ...(fetchMoreResult?.getAgentList?.agents || [])
              ]
            }
          };
        }
      });
    } finally {
      setIsFetchingMore(false);
    }
  }

  return {
    data: data,
    bots: bots,
    error,
    loading,
    refetching: networkStatus === NetworkStatus.refetch,
    isFetchingMore,
    queryResult,
    hasNextPage: data?.getAgentList.hasNextPage || false,
    invalidate,
    fetchMore,
    refetch
  };
}

export default useBotsQuery;
