import React, { ReactNode, useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

import config from 'common/config';

export enum ApolloClientName {
  Subgraph,
  Forta,
  Unlock
}

function ApolloClientProvider({
  children
}: {
  children: ReactNode;
}): JSX.Element {
  const client = useMemo(() => {
    const fortaGraphApiLink = new HttpLink({
      uri: config.apiEndpoint
    });

    const subgraphLink = new HttpLink({
      uri: config.subgraphAPIEndpoint
    });

    const unlockLink = new HttpLink({
      uri: config.unlockSubgraphAPIEndpoint
    });

    return new ApolloClient({
      // with fallback to Forta GraphGL Api
      link: ApolloLink.split(
        (op) => op.getContext().clientName === ApolloClientName.Subgraph,
        subgraphLink,
        ApolloLink.split(
          (op) => op.getContext().clientName === ApolloClientName.Unlock,
          unlockLink,
          fortaGraphApiLink
        )
      ),
      cache: new InMemoryCache()
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloClientProvider;
