import { gql } from '@apollo/client';
import { getExplorerAPIClient } from 'common/lib/apolloExplorerAPIClient';

export interface Agent {
  id: string;
  agent_id?: string;
  name: string;
  developer: string;
  scanners?: string[];
  created_at?: string;
  updated_at?: string;
  description?: string;
  long_description?: string;
  promo_url?: string;
  license_url?: string;
  version?: string;
  projects?: string[];
  repository?: string;
  enabled?: boolean;
  alerts?: number;
  image?: string;
  manifest_ipfs?: string;
  doc_ipfs?: string;
  chainIds?: string[];
  subscription_type?: string;
}

export interface AgentListInput {
  id?: string;
  ids?: string[];
  text?: string;
  developer?: string;
  pageSize?: number;
  pageNumber?: number;
}

export type AgentListApiResponse = {
  hasNextPage: boolean;
  agents: Agent[];
};

export async function getAgents(params: AgentListInput): Promise<Agent[]> {
  const client = getExplorerAPIClient();

  const apiResult = await client.query({
    variables: {
      getAgentInput: params
    },
    query: gql`
      query Retrive($getAgentInput: AgentListInput) {
        getAgentList(input: $getAgentInput) {
          hasNextPage
          agents {
            name
            developer
            agent_id
            created_at
            updated_at
            version
            chainIds
            alerts
            enabled
            image
            manifest_ipfs
            doc_ipfs
            repository
            projects
            description
            long_description
            license_url
            promo_url
            subscription_type
          }
        }
      }
    `
  });
  if (apiResult.data?.getAgentList?.agents?.length) {
    return apiResult.data.getAgentList.agents.map(
      (agent: { agent_id: string }) => {
        return {
          id: agent.agent_id,
          ...agent
        };
      }
    );
  } else {
    return [];
  }
}
