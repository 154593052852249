import React, { useCallback, useState } from 'react';
import { TransactionResponse } from '@ethersproject/providers';
import { toast } from 'react-toastify';

import './ContractCallButton.scss';

import Button from 'common/components-v2/Button/Button';
import Loader from 'common/components/Loader';
import Modal from 'common/components/Modal';
import { ExternalLinkIcon, SignatureIcon } from 'common/components/Icons';
import { getNetworkByChainId } from 'common/lib/networks';
import config from 'common/config';
import { shortenHash } from 'common/lib/utils';

const METAMASK_REJECTION_CODE = 4001;
const network = getNetworkByChainId(config.chainId);

enum ContractCallStep {
  IDLE,
  WAIT_FOR_SIGNATURE,
  SENT,
  CONFIRMED
}

interface ContractCallButtonProps {
  children: string | string[];
  onCall: () => Promise<TransactionResponse | null> | undefined;
  onCompleted?: (tx: TransactionResponse) => void;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  waitForConfirmation?: boolean;
}

export default function ContractCallButton({
  size = 'sm',
  children,
  disabled,
  onCompleted,
  waitForConfirmation,
  onCall
}: ContractCallButtonProps): JSX.Element {
  const [step, setStep] = useState<ContractCallStep>(ContractCallStep.IDLE);
  const [txHash, setTxHash] = useState<string>('');

  const modalClosed = [
    ContractCallStep.CONFIRMED,
    ContractCallStep.IDLE
  ].includes(step);

  const handleClick = useCallback(async () => {
    setStep(ContractCallStep.WAIT_FOR_SIGNATURE);
    try {
      const tx = await onCall();
      if (!tx) throw 'Transaction not received';
      setStep(ContractCallStep.SENT);
      setTxHash(tx.hash);
      toast.info('Tx has been sent.');
      if (waitForConfirmation) {
        await tx.wait(1);
        toast.info('Tx confirmed!');
        setStep(ContractCallStep.CONFIRMED);
      }
      onCompleted && onCompleted(tx);
    } catch (error) {
      setStep(ContractCallStep.IDLE);
      if (error.code === METAMASK_REJECTION_CODE) {
        toast.warn('User rejected the transaction');
      } else {
        console.error(error);
        toast.error('Unknown error, please check the browser console.');
      }
    }
  }, [onCall, onCompleted, waitForConfirmation]);

  return (
    <>
      <Button
        className="ContractCallButton"
        variant="primary"
        size={size}
        loading={step === ContractCallStep.WAIT_FOR_SIGNATURE}
        disabled={disabled || step === ContractCallStep.WAIT_FOR_SIGNATURE}
        onClick={handleClick}
      >
        {children}
      </Button>
      <Modal
        opened={!modalClosed}
        className="ContractCallMessage"
        onCloseModal={() => setStep(ContractCallStep.IDLE)}
      >
        {(function () {
          switch (step) {
            case ContractCallStep.WAIT_FOR_SIGNATURE:
              return (
                <div className="ContractCallMessage__content">
                  <div className="ContractCallMessage__icon">
                    {SignatureIcon}
                  </div>
                  <div className="ContractCallMessage__description">
                    Waiting for wallet signature... <br />
                    Please, confirm the transaction using your wallet interface.
                  </div>
                </div>
              );
            case ContractCallStep.SENT:
              return (
                <div className="ContractCallMessage__content">
                  <div className="ContractCallMessage__icon">
                    <Loader />
                  </div>
                  <div className="ContractCallMessage__description">
                    Transaction has been sent. Please wait until the transaction
                    is confirmed.
                  </div>
                  <div className="ContractCallMessage__external-link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${network.explorerUrl}/tx/${txHash}`}
                    >
                      {shortenHash(txHash)} {ExternalLinkIcon}
                    </a>
                  </div>
                </div>
              );
          }
        })()}
      </Modal>
    </>
  );
}
