import { Paywall } from '@unlock-protocol/paywall';
import networks from '@unlock-protocol/networks';
import { currentENV } from 'common/config';

import prodPlans, { trials as prodTrials } from './plans/plans.prod';
import stagingPlans, { trials as stagingTrials } from './plans/plans.staging';
import { DataPlan, UnlockConfig } from 'forta-app/data/plans/types';
import { UnlockKeyResult } from 'common/hooks/useUnlockMembershipsQuery';
import moment from 'moment';

const plans = currentENV === 'production' ? prodPlans : stagingPlans;
const _trials = currentENV === 'production' ? prodTrials : stagingTrials;

export const planExpired = (key: UnlockKeyResult): boolean => {
  const expiration = moment(Number(key.expiration) * 1000);
  return expiration.isBefore(moment());
};

export const daysToRenewPlan = (key: UnlockKeyResult): string => {
  const expiration = moment(Number(key.expiration) * 1000);
  return expiration.fromNow();
};

export const getUnlockKeyBySubscriptionType = (
  keys: UnlockKeyResult[],
  subscriptionType: string
): UnlockKeyResult | null => {
  const plan = plans.find(
    (plan) => plan.subscription_type === subscriptionType
  );
  if (plan?.unlockAddresses) {
    // look for active subscriptions first, finding the latest expires one
    const activeKeys = keys
      .filter((key) => !planExpired(key) && !key.cancelled)
      .sort((k1, k2) => Number(k2.expiration) - Number(k1.expiration));
    return (
      activeKeys.find((key) =>
        Object.values(plan.unlockAddresses).includes(
          key.lock.address.toLowerCase()
        )
      ) ||
      // if no active subscriptions, only look for regular plans (not showing expired Free Trials)
      keys.find(
        (key) =>
          plan.unlockAddresses.USDC.includes(key.lock.address.toLowerCase()) ||
          plan.unlockAddresses.FORT.includes(key.lock.address.toLowerCase())
      ) ||
      null
    );
  }
  return null;
};

export enum PlanStatus {
  SUBSCRIBED,
  EXPIRED,
  UNSUBSCRIBED
}

export const getPlanStatusBySubscriptionType = (
  keys: UnlockKeyResult[],
  subscriptionType: string
): PlanStatus => {
  const unlockKey = getUnlockKeyBySubscriptionType(keys, subscriptionType);
  if (unlockKey) {
    if (planExpired(unlockKey)) return PlanStatus.EXPIRED;
    return PlanStatus.SUBSCRIBED;
  }
  return PlanStatus.UNSUBSCRIBED;
};

export const trials = _trials.map((trial) => trial.toLowerCase());

export function getUnlockConfig(_plans: DataPlan[]): UnlockConfig {
  return {
    title: 'Forta Plans',
    pessimistic: true,
    skipRecipient: true,
    metadataInputs: [
      {
        name: 'Email (optional; if you want to receive updates)',
        type: 'email',
        required: false,
        public: false
      },
      {
        name: 'Company (optional)',
        type: 'text',
        required: false,
        public: false
      }
    ],
    icon: 'https://app.forta.network/forta_asterisk_light_64.png',
    locks: _plans.reduce((_locks, plan) => {
      const _newLocks: {
        [key: string]: {
          network: number;
          name: string;
          callToAction: { default: string };
        };
      } = { ..._locks };
      if (plan.unlockAddresses.USDC) {
        _newLocks[plan.unlockAddresses.USDC] = {
          network: plan.network,
          name: plan.name,
          callToAction: {
            default: 'Please unlock this demo!'
          }
        };
      }
      if (plan.unlockAddresses.FORT) {
        _newLocks[plan.unlockAddresses.FORT] = {
          network: plan.network,
          name: plan.name,
          callToAction: {
            default: 'Please unlock this demo!'
          }
        };
      }
      return _newLocks;
    }, {})
  };
}

export const unlockConfig = getUnlockConfig(plans);

export const paywall = new Paywall(unlockConfig, networks);

export const isPlanAddress = (address: string): boolean => {
  return plans.some(
    (plan) =>
      plan.unlockAddresses.USDC.toLowerCase() === address.toLowerCase() ||
      plan.unlockAddresses.FORT.toLowerCase() === address.toLowerCase()
  );
};

export const getBotId = (urlPath: string): string => {
  const botId = plans.find((plan) => plan.link === urlPath)?.botId;
  if (botId) return botId;
  return '';
};

export default plans;
