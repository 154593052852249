import './Header.scss';

import Header, { MenuItem } from 'common/components/Header';
import Button from 'common/components-v2/Button/Button';
import config from 'common/config';

export function ExplorerHeader(): JSX.Element {
  const menuItems: MenuItem[] = [
    {
      label: 'Bot Search',
      to: '/',
      exact: true
    },
    {
      to: '/explorer',
      label: 'Alerts',
      exact: true
    },
    {
      label: 'Network',
      href: `${config.appEndpoint}/network`,
      target: '_self'
    }
  ];

  const externalMenuItems: MenuItem[] = [
    {
      label: 'Forta App',
      href: config.appEndpoint,
      externalIcon: true
    }
  ];

  return (
    <Header
      logoUrl="/forta_explorer_logo.svg"
      menuItems={menuItems}
      menuItems2={externalMenuItems}
      renderAdditionalContent={() => (
        <Button
          round
          size="md"
          variant="outline"
          href={config.appEndpoint}
          target="_blank"
          className="ExplorerHeader__subscribe-button"
        >
          Subscribe to Alerts
        </Button>
      )}
      className="ExplorerHeader"
    />
  );
}

export default ExplorerHeader;
