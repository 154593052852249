import React, { useState } from 'react';
import { capitalize } from 'lodash';
import {
  AlertFilterOption,
  AlertFilterOptions,
  AlertSeverities,
  AlertSeverity,
  DEFAULT_BOT_ASSIGNED_SCANNER_COUNT,
  NotificationModalFilterState
} from 'forta-app/slices/notificationSlice';
import Select from 'common/components-v2/Form/Select/Select';
import './AlertFilter.scss';
import Modal from 'common/components/Modal';
import InfoPopover from 'common/components/InfoPopover';
import Button from 'common/components-v2/Button/Button';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { mainNets } from 'forta-app/lib/apis/notificationAPI';
import Input from 'common/components-v2/Form/Input/Input';
import Chip, { ChipContainer } from 'common/components-v2/Chip/Chip';
import { CrossIcon } from 'common/components/Icons';

const getFilterLabel = (
  filterOption: AlertFilterOption
): string | undefined => {
  switch (filterOption) {
    case 'severity':
      return 'Min Severity';
    case 'consensus':
      return 'Consensus';
    case 'chain':
      return 'Chain';
    case 'alertId':
      return 'AlertId';
    default:
      return undefined;
  }
};

const IS_NUMBER = /^[0-9\b]+$/;

const filterOptions = AlertFilterOptions.filter((option) =>
  getFilterLabel(option)
).map((option) => ({
  label: getFilterLabel(option) as string,
  value: option as string
}));

interface AddAlertFilterProps {
  onSubmit: (
    name: string,
    value: string | string[] | Partial<NotificationModalFilterState>
  ) => void;
  currentFilters: NotificationModalFilterState;
}

export const AddAlertFilter = (props: AddAlertFilterProps): JSX.Element => {
  const { onSubmit, currentFilters } = props;
  const [selectedOption, setSelectedOption] = useState<AlertFilterOption>();
  const [modalPosition, setModalPosition] = useState<number[]>();

  return (
    <div className="AlertFilter">
      <div
        className="AlertFilter__dropdown"
        ref={(element) => {
          const position = element?.getBoundingClientRect();

          if (position && !modalPosition) {
            const elementWidth = ((position?.right as number) -
              position?.left) as number;
            const posX = (position?.left as number) + elementWidth;
            const posY = position?.top as number;
            setModalPosition([posX, posY]);
          }
        }}
      >
        <Select
          key={`select_key_${selectedOption}`} // https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
          options={filterOptions}
          name="alert-filter-select"
          placeholder="Add Filter"
          searchable={false}
          value={''}
          closeMenuOnSelect={false}
          onChange={(event) => {
            setSelectedOption(event?.target.value as AlertFilterOption);
          }}
        />
      </div>
      <AlertFilterOptionModal
        isOpen={selectedOption !== undefined}
        selectedFilter={selectedOption}
        currentFilters={currentFilters}
        onClose={() => {
          setSelectedOption(undefined);
        }}
        onSubmit={onSubmit}
        position={modalPosition}
      />
    </div>
  );
};

interface AlertFilterOptionModalProps {
  isOpen: boolean;
  selectedFilter?: AlertFilterOption;
  currentFilters: NotificationModalFilterState;
  onSubmit: (
    name: string,
    value: string | string[] | Partial<NotificationModalFilterState>
  ) => void;
  onClose: () => void;
  position?: number[];
}

export const AlertFilterOptionModal = (
  props: AlertFilterOptionModalProps
): JSX.Element => {
  const {
    isOpen,
    selectedFilter,
    currentFilters,
    onSubmit,
    onClose,
    position
  } = props;

  const { minimumScannerConfirmations, chainId, alertId } = currentFilters;

  const initialScannerFilterValueType = minimumScannerConfirmations
    ? minimumScannerConfirmations.valueType
    : 'percentage';

  const posX = position ? position[0] : 0;
  const posY = position ? position[1] : 0;

  const [filterValue, setFilterValue] = useState<
    string | string[] | Partial<NotificationModalFilterState>
  >();

  const [scannerFilterValueType, setScannerFilterValueType] = useState<
    'number' | 'percentage'
  >(initialScannerFilterValueType);

  const [filteredAlertId, setFilteredAlertId] = useState(alertId);

  return (
    <Modal
      className="AlertFilter__option-modal"
      opened={isOpen}
      onCloseModal={() => {
        setFilterValue(undefined);
        onClose();
      }}
      style={{
        overlay: {
          backgroundColor: 'unset',
          backdropFilter: 'none'
        },
        content: {
          left: posX,
          top: posY,
          maxWidth: '35%',
          minWidth: '25%'
        }
      }}
    >
      <div className="AlertFilter__modal-form">
        {(function () {
          switch (selectedFilter) {
            case 'severity':
              return (
                <div className="AlertFilter__modal-content">
                  <div className="AlertFilter__filter-source-label">
                    Minimum Severity{' '}
                    <InfoPopover
                      content={
                        'Severity indicates the impact level of the alert. Ex: If you select "Medium" any alert below "Medium" severity will not be included in this subscription.'
                      }
                      rightPositioned
                    />
                  </div>
                  <div className="AlertFilter__form-select">
                    <Select
                      options={AlertSeverities.map((element) => ({
                        value: element,
                        label: element.toUpperCase()
                      }))}
                      name="forta-notification-source-type"
                      value={filterValue as AlertSeverity}
                      onChange={(event) => {
                        setFilterValue({
                          ...currentFilters,
                          minimumSeverity: event.target.value
                        });
                      }}
                      searchable={false}
                      placeholder={'Any severity...'}
                    />
                  </div>
                </div>
              );
            case 'consensus':
              return (
                <div className="AlertFilter__modal-content-consensus">
                  <div className="AlertFilter__filter-source-label">
                    Consensus{' '}
                    <InfoPopover
                      content={
                        'Consensus indicate how many scan nodes have confirmed an alert.'
                      }
                      rightPositioned
                    />
                  </div>
                  <div className="NotificationModal__filter-source-label-scanner">
                    {`Bot currently assigned to: ${DEFAULT_BOT_ASSIGNED_SCANNER_COUNT} scanners`}
                  </div>
                  <div className="AlertFilter__form-scan-confirms">
                    <Select
                      options={
                        scannerFilterValueType === 'number'
                          ? [
                              ...Array(
                                DEFAULT_BOT_ASSIGNED_SCANNER_COUNT
                              ).keys()
                            ].map((element) => ({
                              value: (element + 1).toString(),
                              label: (element + 1).toString()
                            }))
                          : [
                              ...Array(
                                DEFAULT_BOT_ASSIGNED_SCANNER_COUNT
                              ).keys()
                            ].map((element) => ({
                              value: Math.floor(
                                ((element + 1) /
                                  DEFAULT_BOT_ASSIGNED_SCANNER_COUNT) *
                                  100
                              ).toString(),
                              label: `${Math.floor(
                                ((element + 1) /
                                  DEFAULT_BOT_ASSIGNED_SCANNER_COUNT) *
                                  100
                              )}% (${
                                element + 1
                              }/${DEFAULT_BOT_ASSIGNED_SCANNER_COUNT})`
                            }))
                      }
                      name="forta-notification-source-type"
                      value={filterValue as AlertSeverity}
                      onChange={(event) => {
                        const value = event.target.value;
                        IS_NUMBER.test(value)
                          ? setFilterValue({
                              ...currentFilters,
                              minimumScannerConfirmations: {
                                value,
                                valueType: scannerFilterValueType
                              }
                            })
                          : {};
                      }}
                      searchable={false}
                      placeholder={
                        scannerFilterValueType === 'number' ? '1' : '16%'
                      }
                    />
                  </div>
                  <div className="AlertFilter__form-source-value-scan-confirms-type">
                    <ToggleButtonGroup
                      color="success"
                      value={scannerFilterValueType}
                      exclusive
                      onChange={(__, value) => {
                        setScannerFilterValueType(value);
                        setFilterValue(undefined);
                      }}
                      size="large"
                      aria-label="Platform"
                    >
                      <ToggleButton value="percentage">%</ToggleButton>
                      <ToggleButton value="number">{'#'}</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              );
            case 'chain':
              return (
                <div className="AlertFilter__modal-content">
                  <div className="AlertFilter__filter-source-label">
                    Chain{' '}
                    <InfoPopover
                      content={
                        'EIP155 identifier of the chain. Alerts returned will only be from the specific chain Id'
                      }
                      rightPositioned
                    />
                  </div>
                  <div className="AlertFilter__form-select">
                    <Select
                      options={Object.keys(mainNets).map((element) => ({
                        value: element,
                        label: mainNets[parseInt(element)]
                      }))}
                      name="forta-notification-source-type"
                      value={chainId ? chainId : ''}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFilterValue({
                          ...currentFilters,
                          chainId: value
                        });
                      }}
                      searchable={false}
                      placeholder={'Select a Network...'}
                    />
                  </div>
                </div>
              );
            case 'alertId':
              return (
                <div className="AlertFilter__modal-content">
                  <div className="AlertFilter__filter-source-label">
                    AlertId{' '}
                    <InfoPopover
                      content={
                        'Unique string to identify this class of finding, primarily used to group similar findings.'
                      }
                      rightPositioned
                    />
                  </div>
                  <div className="AlertFilter__form-select">
                    <Input
                      variant="dark"
                      name={`alertId`}
                      placeholder="Any alert id..."
                      value={filteredAlertId ? filteredAlertId : ''}
                      resettable={true}
                      onChange={(event) => {
                        const value = event.target.value;
                        setFilteredAlertId(value);
                        setFilterValue({
                          ...currentFilters,
                          alertId: value
                        });
                      }}
                    />
                  </div>
                </div>
              );
            default:
              return <></>;
          }
        })()}
        <div className="AlertFilter__form-submit">
          <Button
            round
            size="md"
            variant="secondary"
            onClick={() => {
              setFilterValue(undefined);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            round
            size="md"
            variant="primary"
            disabled={filterValue === undefined}
            onClick={(event) => {
              event?.preventDefault();
              if (filterValue) {
                onSubmit('filter', filterValue);
                onClose();
                setFilterValue(undefined);
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

interface CurrentAlertFilterProps {
  filters: NotificationModalFilterState;
  onClose: (
    name: string,
    value: string | string[] | Partial<NotificationModalFilterState>
  ) => void;
}

export const CurrentAlertFilters = (
  props: CurrentAlertFilterProps
): JSX.Element => {
  const { filters, onClose } = props;

  const { minimumSeverity, minimumScannerConfirmations, chainId, alertId } =
    filters;

  return (
    <ChipContainer alignment="row" className="AlertFilter__chips">
      {minimumSeverity && (
        <Chip
          active={false}
          onClick={() => {
            return;
          }}
          endIcon={
            <ClearFilter
              onClose={() => {
                onClose('filter', { ...filters, minimumSeverity: undefined });
              }}
            />
          }
        >
          Min Severity <span>{capitalize(minimumSeverity)}</span>
        </Chip>
      )}
      {minimumScannerConfirmations && minimumScannerConfirmations.value && (
        <Chip
          active={false}
          onClick={() => {
            return;
          }}
          endIcon={
            <ClearFilter
              onClose={() => {
                onClose('filter', {
                  ...filters,
                  minimumScannerConfirmations: undefined
                });
              }}
            />
          }
        >
          Consensus
          <span>
            {`${minimumScannerConfirmations.value} ${
              minimumScannerConfirmations.valueType === 'percentage'
                ? ' %'
                : ' Nodes'
            }`}
          </span>
        </Chip>
      )}
      {chainId && (
        <Chip
          active={false}
          onClick={() => {
            return;
          }}
          endIcon={
            <ClearFilter
              onClose={() => {
                onClose('filter', { ...filters, chainId: undefined });
              }}
            />
          }
        >
          Network <span>{mainNets[parseInt(chainId)]}</span>
        </Chip>
      )}
      {alertId && (
        <Chip
          active={false}
          onClick={() => {
            return;
          }}
          endIcon={
            <ClearFilter
              onClose={() => {
                onClose('filter', { ...filters, alertId: undefined });
              }}
            />
          }
        >
          AlertId <span>{alertId}</span>
        </Chip>
      )}
    </ChipContainer>
  );
};

interface ClearFilter {
  onClose: () => void;
}

export const ClearFilter = (props: ClearFilter): JSX.Element => {
  const { onClose } = props;
  return (
    <div
      onClick={onClose}
      onKeyDown={() => {
        return;
      }}
      role="presentation"
      className="AlertFilter__chip-icon"
    >
      {CrossIcon}
    </div>
  );
};
