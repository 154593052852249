import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import './GlobalNodePoolsPage.scss';

import Intro from 'common/components-v2/Markup/Intro/Intro';
import Pagination from 'common/components-v2/Pagination/Pagination';
import Table, { TableSorting } from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableBody from 'common/components-v2/Table/TableBody';
import TableCell from 'common/components-v2/Table/TableCell';
import TableHeading from 'common/components-v2/Table/TableHeading';
import TableRow from 'common/components-v2/Table/TableRow';
import TableSubCell from 'common/components-v2/Table/TableSubCell';
import TableSubHeading from 'common/components-v2/Table/TableSubHeading';
import Input from 'common/components-v2/Form/Input/Input';
import Select from 'common/components-v2/Form/Select/Select';
import Link from 'common/components-v2/Button/Link';
import PoolEntity from 'common/components-v2/Entities/Pool/Pool';
import useOffsetPagination from 'common/hooks/useOffsetPagination';
import useQueryPagination from 'common/hooks/useQueryPagination';
import useScannerPoolsWithApyQuery from 'common/hooks/useScannerPoolsWithApyQuery';
import { formatFORT } from 'forta-app/lib/utils';
import { SearchIcon } from 'common/components/Icons';
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from 'common/constants';
import {
  getNetworkByChainId,
  Network,
  networksWhiteList
} from 'common/lib/networks';
import { OnChangeHandlerEvent, Option } from 'common/components-v2/Form/form';
import CommissionDisplay from 'forta-app/components/scan-node-pool/CommissionDisplay';
import { SortingParam } from 'common/lib/query-params';

const PAGE_SIZE = 10;
const TABLE_EL_ID = 'global-scanner-pools-table';

const CHAIN_OPTIONS: Option<Network | null>[] = networksWhiteList.map(
  (chainId) => {
    const network = getNetworkByChainId(chainId);
    return {
      label: network.label,
      value: network.chainId
    };
  }
);
CHAIN_OPTIONS.unshift({ label: 'All Chains', value: null });

export const GlobalNodePoolsPage = (): JSX.Element => {
  const [searchParam, setSearchParam] = useQueryParam('search', StringParam);
  const [chainIdParam, setChainIdParam] = useQueryParam('chainId', NumberParam);
  const [sortingParam, setSortingParam] = useQueryParam(
    'sorting',
    SortingParam
  );

  const sorting = sortingParam || {
    orderBy: 'apyForLastEpoch',
    orderDirection: 'desc'
  };

  const [search, setSearch] = useState(searchParam || '');
  const [searchDebounced] = useDebounce(search, DEFAULT_SEARCH_DEBOUNCE_TIME);
  const searchId = useMemo(
    // normalize 00001 -> 1, 0100 -> 100
    () => searchDebounced.trim().replace(/^0+/, ''),
    [searchDebounced]
  );

  useEffect(
    () => setSearchParam(searchDebounced),
    [searchDebounced, setSearchParam]
  );

  const pagination = useQueryPagination(
    useOffsetPagination(PAGE_SIZE, {
      scrollToElementId: TABLE_EL_ID
    })
  );

  const { scannerPools, hasNextPage, loading, refetching, fetched } =
    useScannerPoolsWithApyQuery({
      params: {
        first: pagination.pageSize,
        skip: pagination.offset.skip,
        id_in: searchId ? [searchId] : undefined,
        chainId: chainIdParam || undefined,
        orderBy: sorting.orderBy,
        orderDirection: sorting.orderDirection
      }
    });

  function handleFilterChange(e: OnChangeHandlerEvent<unknown>): void {
    if (e.target.name === 'search') {
      setSearch(e.target.value as string);
    } else if (e.target.name === 'chainId') {
      setChainIdParam(e.target.value as number);
    }
    pagination.setPage(0);
  }

  function handleSortingChange(sorting: TableSorting): void {
    setSortingParam(sorting);
    pagination.setPage(0);
  }

  return (
    <div className="GlobalNodePoolsPage">
      <Intro>
        <Intro.DocsButton
          label="How to stake"
          href="https://docs.forta.network/en/latest/scanner-pools/#delegating-to-a-pool"
        />
        <Intro.Title>
          Delegation <span className="color-purple">Pools</span>
        </Intro.Title>
        <Intro.Description>
          Discover, decide, delegate. Browse scan node pools from the list
          below, select the ones that best fit your goals, and delegate FORT via
          Polygon to contribute to the security of the Forta Network and earn
          rewards. <br />
          <Link
            href="https://docs.forta.network/en/latest/delegated-staking-introduction/"
            target="_blank"
          >
            Learn more about the process here.
          </Link>
        </Intro.Description>
      </Intro>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="GlobalNodePoolsPage__search-form"
      >
        <Input
          resettable
          name="search"
          variant="gray"
          placeholder="Search by Id"
          value={search}
          icon={{ left: SearchIcon }}
          onChange={handleFilterChange}
          className="GlobalNodePoolsPage__search-input"
        />
        <Select
          name="chainId"
          clearable={false}
          value={chainIdParam || null}
          options={CHAIN_OPTIONS}
          onChange={handleFilterChange}
          className="GlobalNodePoolsPage__chain-select"
        />
      </form>
      <Table
        id={TABLE_EL_ID}
        minWidth={1000}
        sorting={sorting}
        onSortingChange={handleSortingChange}
        className="GlobalNodePoolsPage__table"
      >
        <TableHead>
          <TableHeading title="Pool" flex={24}>
            <TableSubHeading sortable name="id" title="Id" flex={6} />
            <TableSubHeading
              sortable
              name="apyForLastEpoch"
              title="Est. Annual Rewards"
              tooltip="Estimated annual rewards for delegators in this pool. This is based on last epoch's data, extrapolated over a year. This is subject to change based on factors such as the node's performance, commission changes, allocated stake, among others."
              flex={10}
            />
            <TableSubHeading
              sortable
              name="commission"
              title="Commission"
              flex={8}
            />
          </TableHeading>
          <TableHeading title="Stake" flex={18}>
            <TableSubHeading
              sortable
              name="stakeOwned"
              title="Owned"
              flex={6}
            />
            <TableSubHeading
              sortable
              name="stakeDelegated"
              title="Delegated"
              flex={6}
            />
            <TableSubHeading
              sortable
              name="stakeAllocated"
              title="Allocated"
              flex={6}
            />
          </TableHeading>
        </TableHead>
        <TableBody
          loading={loading || refetching}
          empty={fetched && scannerPools.length === 0}
        >
          {scannerPools.map((pool) => (
            <TableRow key={pool.id}>
              <TableCell flex={24}>
                <TableSubCell
                  flex={6}
                  value={
                    <PoolEntity
                      to={`/nodePool/${pool.id}/`}
                      id={pool.id}
                      chainId={pool.chainId}
                    />
                  }
                />
                <TableSubCell
                  value={
                    pool.apyForLastEpoch > 0
                      ? pool.apyForLastEpoch.toString() + '%'
                      : '-'
                  }
                  flex={10}
                />
                <TableSubCell
                  value={<CommissionDisplay pool={pool} />}
                  flex={8}
                />
              </TableCell>
              <TableCell flex={18}>
                <TableSubCell
                  value={`${formatFORT(pool.stakeOwned, 0)} FORT`}
                  flex={6}
                />
                <TableSubCell
                  value={`${formatFORT(pool.stakeDelegated, 0)} FORT`}
                  flex={6}
                />
                <TableSubCell
                  value={`${formatFORT(pool.stakeAllocated, 0)} FORT`}
                  flex={6}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        page={pagination.page}
        limit={pagination.pageSize}
        itemsCount={scannerPools.length}
        loading={loading || refetching}
        hasNextPage={hasNextPage}
        onPrevPage={pagination.goPrevPage}
        onNextPage={pagination.goNextPage}
      />
    </div>
  );
};
