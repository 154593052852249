import Button from 'common/components-v2/Button/Button';
import './AccountPage.scss';
import PlanPurchaseModal from 'forta-app/components/account/PlanPurchaseModal';
import React, { memo, useEffect, useState } from 'react';
import useUnlockMembershipsQuery, {
  UnlockKeyResult
} from 'common/hooks/useUnlockMembershipsQuery';
import { useWeb3React } from '@web3-react/core';

import Loader from 'common/components/Loader';
import Table from 'common/components-v2/Table/Table';
import TableHead from 'common/components-v2/Table/TableHead';
import TableHeading from 'common/components-v2/Table/TableHeading';
import TableBody from 'common/components-v2/Table/TableBody';
import TableRow from 'common/components-v2/Table/TableRow';
import TableCell from 'common/components-v2/Table/TableCell';
import plans, {
  getUnlockConfig,
  paywall,
  planExpired,
  trials
} from 'forta-app/data/plans';
import moment from 'moment';
import {
  CrossIcon,
  SmallCheckIcon,
  WarningIcon
} from 'common/components/Icons';
import classNames from 'classnames';
import Erc20Contract from 'forta-app/lib/contract-interactors/erc20Contract';
import { toast } from 'react-toastify';
import Modal from 'common/components/Modal';
import ContractCallButton from 'forta-app/components/ContractCallButton';
import TransactionTable from 'forta-app/components/transactions/TransactionTable';
import TabIntro from 'forta-app/components/TabIntro';

import { formatUnits } from 'ethers/lib/utils';
import { useJWT } from 'forta-app/app/hooks';
import ActionCell from '../../../common/components-v2/Table/ActionCell';
import { useConnectModal } from 'forta-app/components/wallet/useConnectModal';

enum RenewalStatus {
  NEEDS_ALLOWANCE,
  NEEDS_BALANCE,
  UNRENEWABLE,
  CANCELLED,
  OK
}

export const RenewalStatusCell = ({
  unlockKey
}: {
  unlockKey: UnlockKeyResult;
}): JSX.Element => {
  const web3React = useWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const [renewalStatus, setRenewalStatus] = useState<RenewalStatus>(
    RenewalStatus.OK
  );
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const jwt = useJWT();
  const { open: openConnectModal } = useConnectModal();

  useEffect(() => {
    const fetchRenewalStatus = async (): Promise<void> => {
      if (trials.includes(unlockKey.lock.address.toLowerCase())) {
        setRenewalStatus(RenewalStatus.UNRENEWABLE);
        return;
      }

      if (unlockKey.cancelled) {
        setRenewalStatus(RenewalStatus.CANCELLED);
        return;
      }

      setLoading(true);
      const erc20Contract = new Erc20Contract(
        web3React,
        unlockKey.lock.tokenAddress
      );
      const allowance = await erc20Contract.allowance(
        web3React.account || '',
        unlockKey.lock.address
      );

      if (allowance.lt(unlockKey.lock.price)) {
        setRenewalStatus(RenewalStatus.NEEDS_ALLOWANCE);
      } else {
        setRenewalStatus(RenewalStatus.OK);
      }

      setLoading(false);
    };
    fetchRenewalStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    web3React.account,
    unlockKey.lock.address,
    unlockKey.lock.price,
    unlockKey.lock.tokenAddress
  ]);

  const plan = plans.find((_plan) => {
    return (
      _plan.unlockAddresses.FORT === unlockKey.lock.address ||
      _plan.unlockAddresses.USDC === unlockKey.lock.address
    );
  });

  const onRenewClick = async (): Promise<void> => {
    if (plan) {
      const unlockConfig = getUnlockConfig([
        {
          ...plan,
          unlockAddresses: {
            USDC: plan.unlockAddresses['USDC'],
            FORT: plan.unlockAddresses['FORT'],
            trial: plan.unlockAddresses['trial']
          }
        }
      ]);
      if (jwt) {
        paywall.connect(web3React.provider?.provider);
        paywall.loadCheckoutModal(unlockConfig);
      } else {
        openConnectModal();
      }
    }
  };

  return (
    <div className="RenewalStatus">
      {loading ? (
        <Loader />
      ) : renewalStatus === RenewalStatus.OK ? (
        <div className="RenewalStatus__label">
          <span className="icon-wrapper">{SmallCheckIcon}</span> Automatic
          Renewal
        </div>
      ) : renewalStatus === RenewalStatus.NEEDS_ALLOWANCE ? (
        <div>
          <div className="RenewalStatus__label">
            <span className="icon-wrapper">{WarningIcon}</span> Not enough funds
            approved
          </div>
          <Modal
            opened={modalOpened}
            title="Plan Renewing..."
            onCloseModal={() => setModalOpened(false)}
            className="RenewalStatus__modal"
          >
            <div className="RenewalStatus__modal-description">
              It might take some time to renew the plan after funds are
              approved.
            </div>
            <div className="RenewalStatus__modal-button">
              <Button
                size="sm"
                variant="primary"
                onClick={() => setModalOpened(false)}
              >
                OK
              </Button>
            </div>
          </Modal>
          <Button
            size="sm"
            variant="tertiary"
            onClick={async () => {
              try {
                const erc20Contract = new Erc20Contract(
                  web3React,
                  unlockKey.lock.tokenAddress
                );
                await erc20Contract.approve(
                  unlockKey.lock.address,
                  unlockKey.lock.price
                );
                toast.success('Funds approved');
                if (planExpired(unlockKey)) setModalOpened(true);
              } catch (e) {
                toast.warn('Unable to approve funds');
              }
            }}
          >
            Approve more funds
          </Button>
        </div>
      ) : renewalStatus === RenewalStatus.UNRENEWABLE || !plan ? (
        <div className="RenewalStatus__label">Not renewable</div>
      ) : renewalStatus === RenewalStatus.CANCELLED ? (
        <Button
          round
          variant="primary"
          size="md"
          className="RenewalStatus__renew-button"
          onClick={onRenewClick}
        >
          Renew
        </Button>
      ) : null}
    </div>
  );
};

function PlanStatusActions({
  unlockKey
}: {
  unlockKey: UnlockKeyResult;
}): JSX.Element {
  const web3React = useWeb3React();

  const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false);
  const [renewModalOpened, setRenewModalOpened] = useState<boolean>(false);
  const [renewalStatus, setRenewalStatus] = useState<RenewalStatus>(
    RenewalStatus.OK
  );

  useEffect(() => {
    const fetchRenewalStatus = async (): Promise<void> => {
      const erc20Contract = new Erc20Contract(
        web3React,
        unlockKey.lock.tokenAddress
      );
      const allowance = await erc20Contract.allowance(
        web3React.account || '',
        unlockKey.lock.address
      );

      if (allowance.lt(unlockKey.lock.price)) {
        setRenewalStatus(RenewalStatus.NEEDS_ALLOWANCE);
      } else {
        setRenewalStatus(RenewalStatus.OK);
      }
    };
    fetchRenewalStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    web3React.account,
    unlockKey.lock.address,
    unlockKey.lock.price,
    unlockKey.lock.tokenAddress
  ]);

  function handleCancelAutoRenewal(): void {
    setCancelModalOpened(true);
  }

  async function handleRenew(): Promise<void> {
    try {
      const erc20Contract = new Erc20Contract(
        web3React,
        unlockKey.lock.tokenAddress
      );
      await erc20Contract.approve(unlockKey.lock.address, unlockKey.lock.price);
      toast.success('Funds approved');
      if (planExpired(unlockKey)) setRenewModalOpened(true);
    } catch (e) {
      toast.warn('Unable to approve funds');
    }
  }

  return (
    <>
      <ActionCell
        options={[
          {
            label: 'Renew',
            disabled:
              renewalStatus === RenewalStatus.OK ||
              renewalStatus !== RenewalStatus.NEEDS_ALLOWANCE,
            onClick: handleRenew
          },
          {
            label: 'Cancel',
            disabled: renewalStatus !== RenewalStatus.OK,
            onClick: handleCancelAutoRenewal
          }
        ]}
      />
      <Modal
        opened={cancelModalOpened}
        title="Are you sure you want to cancel auto renewal?"
        onCloseModal={() => setCancelModalOpened(false)}
        className="PlanStatusActions__modal"
      >
        <div>
          <ContractCallButton
            onCall={async () => {
              if (web3React.account) {
                const erc20Contract = new Erc20Contract(
                  web3React,
                  unlockKey.lock.tokenAddress
                );
                return erc20Contract.approve(unlockKey.lock.address, '0');
              }
              return null;
            }}
            onCompleted={() => setCancelModalOpened(false)}
          >
            Confirm cancellation
          </ContractCallButton>
        </div>
      </Modal>
      <Modal
        opened={renewModalOpened}
        title="Plan Renewing..."
        onCloseModal={() => setRenewModalOpened(false)}
        className="RenewalStatus__modal"
      >
        <div className="RenewalStatus__modal-description">
          It might take some time to renew the plan after funds are approved.
        </div>
        <div className="RenewalStatus__modal-button">
          <Button
            size="sm"
            variant="primary"
            onClick={() => setRenewModalOpened(false)}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default function AccountPage(): JSX.Element {
  const web3React = useWeb3React();
  const [purchaseModalOpened, setPurchaseModalOpened] =
    useState<boolean>(false);
  const PureTxTable = memo(TransactionTable);
  const { activeMembershipAddresses, keys, loading, fetched, refetch } =
    useUnlockMembershipsQuery({
      params: {
        owner: web3React.account || ''
      }
    });

  const currentPlans = keys;

  const purchaseModal = (
    <PlanPurchaseModal
      alreadyPurchasedPlanAddresses={activeMembershipAddresses}
      opened={purchaseModalOpened}
      onUpdate={() => refetch()}
      onCloseModal={() => setPurchaseModalOpened(false)}
    />
  );

  if (currentPlans.length === 0) {
    return (
      <div className="AccountPage">
        <TabIntro>
          <TabIntro.Content>
            <TabIntro.Title>No plans in your account</TabIntro.Title>
            <TabIntro.Description>
              To access data and/or deploy your detection bots, review our
              plans.
            </TabIntro.Description>
            <p>
              <Button
                size="lg"
                variant="primary"
                loading={loading}
                disabled={loading}
                onClick={() => setPurchaseModalOpened(true)}
                className="AccountPage__buy-plan-button"
              >
                Buy a Plan
              </Button>
            </p>
          </TabIntro.Content>
        </TabIntro>
        {purchaseModal}
      </div>
    );
  }

  return (
    <div className="AccountPage">
      <div className="AccountPage__plans">
        <div className="AccountPage__header">
          <div className="AccountPage__plans-title">Billing</div>
          <div className="AccountPage__header-action">
            <Button
              size="md"
              variant="primary"
              onClick={() => setPurchaseModalOpened(true)}
            >
              Buy New Plan
            </Button>
          </div>
        </div>
        <div className="AccountPage__keys">
          <Table minWidth={1000}>
            <TableHead>
              <TableHeading title="Plan Name" flex={3} />
              <TableHeading title="Monthly Cost" flex={2} />
              <TableHeading title="Plan Status" flex={3} />
              <TableHeading title="Current Period" flex={3} />
              <TableHeading title="Days to renewal day" flex={3} />
              <TableHeading title="Renewal Status" flex={4} />
              <TableHeading title="" flex={1} />
            </TableHead>
            <TableBody empty={fetched && keys.length === 0} loading={loading}>
              {keys
                .map((key, index): JSX.Element | null => {
                  const plan = plans.find((_plan) => {
                    return (
                      _plan.unlockAddresses.FORT === key.lock.address ||
                      _plan.unlockAddresses.USDC === key.lock.address
                    );
                  });
                  const trial = trials.find(
                    (_trial) => _trial === key.lock.address
                  );

                  if (!plan && !trial) return null;

                  const name = key.lock.name;
                  const currency =
                    plan?.unlockAddresses?.FORT === key.lock.address
                      ? 'FORT'
                      : 'USDC';

                  const displayedPrice = formatUnits(
                    key.lock.price,
                    currency === 'USDC' ? 6 : 18
                  );

                  const createdAt = moment(Number(key.createdAt) * 1000);
                  const expiration = moment(Number(key.expiration) * 1000);

                  const classes = classNames('AccountPage__plan-row', {
                    'AccountPage__plan-row--expired': expiration.isBefore(
                      moment()
                    )
                  });

                  return (
                    <TableRow
                      key={`key-table-${key.id}-${index}`}
                      className={classes}
                    >
                      <TableCell flex={3}>
                        <div className="AccountPage__plans-cell">{name}</div>
                      </TableCell>
                      <TableCell flex={2}>
                        <div className="AccountPage__plans-cell">
                          {displayedPrice} {currency}
                        </div>
                      </TableCell>
                      <TableCell flex={3}>
                        <div className="AccountPage__plans-cell AccountPage__plan-status">
                          {expiration.isBefore(moment()) ? (
                            <>
                              <span className="icon-wrapper">{CrossIcon}</span>{' '}
                              Inactive
                            </>
                          ) : (
                            <>
                              <span className="icon-wrapper">
                                {SmallCheckIcon}
                              </span>{' '}
                              Active
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell flex={3}>
                        <div className="AccountPage__plans-cell">
                          {createdAt.format('MMMM Do')} -{' '}
                          {expiration.format('MMMM Do')}
                        </div>
                      </TableCell>
                      <TableCell flex={3}>
                        <div className="AccountPage__plans-cell AccountPage__plan-renew-date">
                          {expiration.isBefore(moment())
                            ? 'Expired'
                            : expiration.fromNow()}
                        </div>
                      </TableCell>
                      <TableCell flex={4}>
                        <div className="AccountPage__plans-cell">
                          <RenewalStatusCell unlockKey={key} />
                        </div>
                      </TableCell>
                      <TableCell flex={1} className="AccountPage__actions-cell">
                        {!key.cancelled ? (
                          <PlanStatusActions unlockKey={key} />
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })
                .filter(Boolean)}
            </TableBody>
          </Table>
        </div>
      </div>
      {web3React.account && (
        <div className="AccountPage__transactions">
          <PureTxTable address={web3React.account} />
        </div>
      )}
      {purchaseModal}
    </div>
  );
}
