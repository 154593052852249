import { gql } from '@apollo/client';
import useGraphQuery from './useGraphQuery';
import { ApolloClientName } from 'common/components/ApolloClientProvider';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';
import { useUnlockReceiptsFilteredQuery } from './useUnlockReceiptsQuery';
import useScannerPoolsQuery from './useScannerPoolsQuery';
import { useMemo } from 'react';
import moment from 'moment';
import { TransactionType } from 'forta-app/lib/transactions-storage/TransactionsStorage';
import plans from 'forta-app/data/plans';
import { shortenHash } from 'common/lib/utils';

type TxQueryVariables = {
  id: string;
  timestampFilterValue: number;
};

type RewardClaimedEvent = {
  timestamp: string;
  value: string;
  transaction: {
    id: string;
  };
  subject: {
    subjectType: SubjectType;
    id: string;
  };
};

type StakeEvent = {
  timestamp: string;
  amount: string;
  subject: {
    id: string;
    subjectType: SubjectType;
  };
  transaction: {
    id: string;
  };
};

interface StakeInfo {
  subject: {
    subjectId: string;
    subjectType: number;
  };
}

type AccountTxs = {
  claimedRewardEvents?: RewardClaimedEvent[];
  staker: {
    stakes: (StakeInfo & {
      stakeDepositedEvents: StakeEvent[];
      withdrawalExecutedEvents: StakeEvent[];
    })[];
  };
};

type TxQueryResponse = {
  account: AccountTxs;
};

type TxsQueryParams = {
  address: string;
};

interface TxEvent {
  timestamp: number;
  amount: string;
  label: string;
  subject: {
    id: string;
    subjectType?: SubjectType;
  };
  transaction: {
    id: string;
    type: TransactionType;
    token: 'FORT' | 'USDC';
  };
}

export type TxQueryData = {
  transactionEvents: TxEvent[];
  fetched: boolean;
  loading: boolean;
};

export const FETCH_TXS_QUERY = gql`
  fragment SubjectData on Subject {
    subjectType
    id
  }

  query FetchTxsQuery($id: ID!, $timestampFilterValue: Int) {
    account(id: $id) {
      id
      claimedRewardEvents(
        where: { timestamp_gt: $timestampFilterValue }
        first: 50
        orderBy: timestamp
        orderDirection: asc
      ) {
        timestamp
        transaction {
          id
        }
        subject {
          ...SubjectData
        }
        value
      }
      staker {
        stakes(where: { subject_: { subjectType_not: 0 } }) {
          subject {
            subjectId
          }
          stakeDepositedEvents(
            where: { timestamp_gt: $timestampFilterValue }
            first: 20
            orderBy: timestamp
            orderDirection: asc
          ) {
            timestamp
            transaction {
              id
            }
            amount
            subject {
              ...SubjectData
            }
          }
          withdrawalExecutedEvents(
            where: { timestamp_gt: $timestampFilterValue }
            first: 20
            orderBy: timestamp
            orderDirection: asc
          ) {
            timestamp
            transaction {
              id
            }
            amount
            subject {
              ...SubjectData
            }
          }
        }
      }
    }
  }
`;

// Could be useful later for more complex labeling but I think it adds too much
// i/o overhead
// async function generateTxEventLabel(
//   txEvent: TxEvent,
//   provider: ethers.providers.JsonRpcProvider,
//   eventData: UnlockReceiptResult
// ): Promise<TxEvent> {
//   const txID = txEvent.transaction.id;
//   const txResponse = await provider.getTransaction(txID);
//   // The first 4 bytes of a smart contract tx calldata is the methodId
//   // We can use this to create custom labels for each event
//   const methodId = ethers.utils.hexDataSlice(txResponse.data, 0, 4);
//   if (
//     methodId === transactionTypeDetails[TransactionType.PURCHASE_PLAN].methodId
//   ) {
//     txEvent.label = `${labelPlanPurchase(eventData)} Purchased`;
//     txEvent.transaction.type = TransactionType.PURCHASE_PLAN;
//     return txEvent;
//   } else {
//     txEvent.label = `${labelPlanPurchase(eventData)} Renewed`;
//     return txEvent;
//   }
// }

function useFetchUserTxs(params: TxsQueryParams): TxQueryData {
  const address = params.address.toLowerCase();
  const timestampFilterValue = moment().subtract(3, 'months').unix();

  const txsQuery = useGraphQuery<TxQueryVariables, TxQueryResponse>({
    query: FETCH_TXS_QUERY,
    variables: {
      id: address,
      timestampFilterValue
    },
    clientName: ApolloClientName.Subgraph
  });

  const {
    receipts,
    fetched: isReceiptsFetched,
    loading: isReceiptsLoading
  } = useUnlockReceiptsFilteredQuery({
    params: {
      timestampFilterValue,
      payer: address,
      first: 50
    }
  });

  // TODO Remove this temporary fix for the subjectType field once it is fixed in the subgraph.
  const {
    scannerPools,
    loading: isScannerPoolsLoading,
    fetched: isScannerPoolsFetched
  } = useScannerPoolsQuery({
    params: { owner: address }
  });

  const doesNotHaveData = !txsQuery.data && receipts.length === 0;
  const dataNotFetched =
    !isScannerPoolsFetched || !isReceiptsFetched || !txsQuery.fetched;

  const transactionEvents = useMemo(() => {
    if (doesNotHaveData || dataNotFetched) return;

    const ownerPoolIds = scannerPools.map((s) => s.id.toLowerCase());
    const transactionEvents: TxEvent[] = [];

    // Loop through receipts and create new TxEvents
    for (const receipt of receipts) {
      const associatedPlanForReceipt = plans.find((plan) => {
        return Object.values(plan.unlockAddresses).includes(
          receipt.lockAddress
        );
      });

      const tempEvent: TxEvent = {
        timestamp: Number(receipt.timestamp),
        amount: receipt.amountTransferred,
        label: `${associatedPlanForReceipt?.name || 'Unknown'} Plan Purchased`,
        subject: {
          id: receipt.receiptNumber,
          subjectType: undefined
        },
        transaction: {
          id: receipt.id,
          type: TransactionType.RENEW_PLAN,
          token:
            associatedPlanForReceipt?.unlockAddresses['FORT'] ===
            receipt.lockAddress
              ? 'FORT'
              : 'USDC'
        }
      };

      transactionEvents.push(tempEvent);
    }

    if (txsQuery.data) {
      // Loop through all claimedRewardEvents
      if (txsQuery.data.account?.claimedRewardEvents) {
        for (const claimedRewardEvent of txsQuery.data.account
          .claimedRewardEvents) {
          const tempEvent: TxEvent = {
            timestamp: Number(claimedRewardEvent.timestamp) * 1000,
            amount: claimedRewardEvent.value,
            label: 'Claimed Reward',
            subject: { ...claimedRewardEvent.subject },
            transaction: {
              id: claimedRewardEvent.transaction.id,
              type: TransactionType.CLAIM_REWARD,
              token: 'FORT'
            }
          };

          transactionEvents.push(tempEvent);
        }
      }

      // Loop through all stakes
      if (txsQuery.data.account?.staker?.stakes) {
        for (const stake of txsQuery.data.account.staker.stakes) {
          let type = stake.subject.subjectType;

          // Fix mislabeling of stake subjectType
          if (
            !ownerPoolIds.includes(stake.subject.subjectId) &&
            type === SubjectType.SCANNERPOOL
          ) {
            type = SubjectType.SCANNERPOOL_DELEGATOR;
          }

          // Loop through all withdraw events for each stake
          for (const withdrawalExecutedEvent of stake.withdrawalExecutedEvents) {
            const tempEvent: TxEvent = {
              timestamp: Number(withdrawalExecutedEvent.timestamp) * 1000,
              amount: withdrawalExecutedEvent.amount,
              label: 'Withdraw',
              subject: { ...withdrawalExecutedEvent.subject },
              transaction: {
                id: withdrawalExecutedEvent.transaction.id,
                type: TransactionType.STAKE_WITHDRAW,
                token: 'FORT'
              }
            };

            transactionEvents.push(tempEvent);
          }

          // Loop through all stake deposit events for each stake
          for (const stakeDepositedEvent of stake.stakeDepositedEvents) {
            const tempEvent: TxEvent = {
              timestamp: Number(stakeDepositedEvent.timestamp) * 1000,
              amount: stakeDepositedEvent.amount,
              label: `Stake on ${
                stakeDepositedEvent.subject.subjectType ===
                SubjectType.SCANNERPOOL
                  ? `Scanner Pool ${stakeDepositedEvent.subject.id}`
                  : `Bot ${shortenHash(stakeDepositedEvent.subject.id)}`
              }`,
              subject: { ...stakeDepositedEvent.subject },
              transaction: {
                id: stakeDepositedEvent.transaction.id,
                type: TransactionType.STAKE_DEPOSIT,
                token: 'FORT'
              }
            };

            transactionEvents.push(tempEvent);
          }
        }
      }
    }

    const sort = (r1: TxEvent, r2: TxEvent): number =>
      r2.timestamp - r1.timestamp;

    transactionEvents.sort(sort);

    return transactionEvents;
  }, [receipts, scannerPools, txsQuery.data, dataNotFetched, doesNotHaveData]);

  return {
    fetched: isScannerPoolsFetched && txsQuery.fetched && isReceiptsFetched,
    loading: isScannerPoolsLoading || txsQuery.loading || isReceiptsLoading,
    transactionEvents: transactionEvents || []
  };
}

export default useFetchUserTxs;
