import React, { useState } from 'react';
import dayjs from 'dayjs';
import { formatEther } from 'ethers/lib/utils';

import FancyContainer from 'common/components-v2/Markup/FancyContainer/FancyContainer';
import ChartTabs from 'common/components-v2/Chart/Tabs/Tabs';
import LineChart from 'common/components-v2/Chart/Line/LineChart';
import useTimeSeries, {
  boundsFromNow
} from 'common/components-v2/Chart/useTimeSeries';
import { AccountStakeQueryResult } from 'common/hooks/useAccountStakeQuery';
import { formatNumber } from 'forta-app/lib/utils';
import {
  CHART_INTERVAL,
  CHART_INTERVAL_OPTIONS
} from 'common/components-v2/Chart/constants';
import { SubjectType } from 'forta-app/lib/contract-interactors/stakingContract';

enum ChartType {
  Delegating = 'delegating',
  ClaimedRewards = 'rewards'
}

const CHART_OPTIONS = [
  { label: 'Delegating', value: ChartType.Delegating },
  { label: 'Rewards', value: ChartType.ClaimedRewards }
];

type DelegationsChartProps = {
  stake: AccountStakeQueryResult;
  loading?: boolean;
  className?: string;
};

function DelegationsChart(props: DelegationsChartProps): JSX.Element {
  const { stake, loading, className } = props;

  const [interval, setInterval] = useState(CHART_INTERVAL.OneMonth);
  const [chartType, setChartType] = useState(ChartType.Delegating);

  const delegationSeriesData = useTimeSeries(stake?.history.delegations, {
    xAccessor: 'timestamp',
    yAccessor: 'amount',
    timeframe: 'day',
    cumulativeSum: true,
    bounds: boundsFromNow({ interval }),
    prepare: (d) => ({
      ...d,
      amount: Number(formatEther(d.amount.toString(10)))
    }),
    deps: [interval]
  });

  const delegatedRewardsClaimed = stake?.claimedRewards.filter(
    (event) => event.subjectType === SubjectType.SCANNERPOOL_DELEGATOR
  );

  const delegationClaimedRewardSeriesData = useTimeSeries(
    delegatedRewardsClaimed,
    {
      xAccessor: 'timestamp',
      yAccessor: 'amount',
      timeframe: 'day',
      cumulativeSum: true,
      bounds: boundsFromNow({ interval }),
      prepare: (d) => ({
        ...d,
        amount: Number(formatEther(d.amount.toString(10)))
      }),
      deps: [interval]
    }
  );

  return (
    <FancyContainer variant="gradient" className={className}>
      <FancyContainer.Filters>
        <ChartTabs
          separator
          name="chart-type"
          value={chartType}
          items={CHART_OPTIONS}
          onChange={(e) => setChartType(e.target.value)}
        />
        <ChartTabs
          name="interval"
          value={interval}
          items={CHART_INTERVAL_OPTIONS}
          onChange={(e) => setInterval(e.target.value)}
        />
      </FancyContainer.Filters>
      <FancyContainer.FullWidth>
        <LineChart
          type="time"
          data={
            chartType === ChartType.Delegating
              ? delegationSeriesData
              : delegationClaimedRewardSeriesData
          }
          empty={
            chartType === ChartType.Delegating
              ? delegationSeriesData.length === 0
              : delegationClaimedRewardSeriesData.length === 0
          }
          xAccessor="timestamp"
          loading={loading}
          lines={[
            {
              label: 'Stake',
              yAccessor: 'amount',
              formatter: (v) => `${formatNumber(v)} FORT`
            }
          ]}
          tooltipTitle={(v) => dayjs.unix(Number(v)).format('MMM D, YYYY')}
        />
      </FancyContainer.FullWidth>
    </FancyContainer>
  );
}

export default DelegationsChart;
