import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';
import useOutsideClick from 'react-cool-onclickoutside';
import { Collapse } from 'react-collapse';

import './Header.scss';

import BaseButton from 'common/components-v2/Button/BaseButton';
import Button from 'common/components-v2/Button/Button';
import useViewPort from 'common/hooks/useViewPort';
import {
  ChevronDownIcon,
  CrossIcon,
  ExternalLinkIcon,
  MenuIcon
} from 'common/components/Icons';

type HeaderProps = {
  menuItems: MenuItem[];
  menuItems2?: MenuItem[];
  renderAdditionalContent?: () => React.ReactNode;
  logoUrl: string;
  className?: string;
};

export type MenuItem = {
  to?: string;
  href?: string;
  target?: '_blank' | '_self' | '_parent';
  label: string;
  nested?: NestedMenuItem[];
  new?: boolean;
  exact?: boolean;
  externalIcon?: boolean;
};

export type NestedMenuItem = {
  to?: string;
  href?: string;
  target?: '_blank' | '_self' | '_parent';
  icon: JSX.Element;
  title: string;
  description: string;
  new?: boolean;
  upcoming?: boolean;
  disabled?: boolean;
};

function MenuItem(props: {
  item: MenuItem;
  onClose: () => void;
  className?: string;
}): JSX.Element {
  const { item, onClose, className } = props;

  const { isMdDown } = useViewPort();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasNestedItems = !!item.nested?.length;

  const elementRef = useOutsideClick(() => setIsExpanded(false), {
    disabled: isMdDown
  });

  const content = (
    <>
      <span className="MenuItem__label">
        {item.new && (
          <span
            className={cn('MenuItem__badge', {
              'MenuItem__badge--new': item.new
            })}
          >
            new
          </span>
        )}
        {item.label}
      </span>
      {hasNestedItems && (
        <span
          className={cn('MenuItem__icon', {
            'MenuItem__icon--expanded': isExpanded
          })}
        >
          {ChevronDownIcon}
        </span>
      )}
      {item.href && item.externalIcon && (
        <span className="MenuItem__icon">{ExternalLinkIcon}</span>
      )}
    </>
  );

  const nestedItems = (
    <ul
      className={cn('MenuNestedItems', {
        ['MenuNestedItems--expanded']: isExpanded
      })}
    >
      {(item.nested || []).map((item) => (
        <li key={item.title}>
          <BaseButton
            to={item.to}
            href={item.href}
            target={item.target || '_blank'}
            disabled={item.disabled}
            className={cn('MenuNestedItem', {
              ['MenuNestedItem--disabled']: item.disabled
            })}
            onClick={() => {
              setIsExpanded(false);
              onClose();
            }}
          >
            <span className="MenuNestedItem__icon">{item.icon}</span>
            <span className="MenuNestedItem__body">
              <span className="MenuNestedItem__title">
                {item.title}
                {(item.new || item.upcoming) && (
                  <span
                    className={cn('MenuNestedItem__badge', {
                      ['MenuNestedItem__badge--new']: item.new,
                      ['MenuNestedItem__badge--upcoming']: item.upcoming
                    })}
                  >
                    {item.new && 'new'}
                    {item.upcoming && 'soon'}
                  </span>
                )}
              </span>
              <span className="MenuNestedItem__description">
                {item.description}
              </span>
            </span>
          </BaseButton>
        </li>
      ))}
    </ul>
  );

  const Element = hasNestedItems || item.href ? 'a' : NavLink;
  const elementProps = item.to ? { activeClassName: 'MenuItem--active' } : {}; // allows to avoid React warning

  return (
    <li
      ref={elementRef}
      onMouseEnter={
        isMdDown || !hasNestedItems ? undefined : () => setIsExpanded(true)
      }
      onMouseLeave={
        isMdDown || !hasNestedItems ? undefined : () => setIsExpanded(false)
      }
    >
      <Element
        to={item.to}
        href={hasNestedItems ? '#' : item.href}
        exact={item.exact}
        target={item.href ? item.target || '_blank' : undefined}
        rel={item.href ? 'noopener noreferrer' : undefined}
        onClick={hasNestedItems ? () => setIsExpanded(!isExpanded) : onClose}
        className={cn('MenuItem', className, {
          'MenuItem--active': isExpanded && !item.href,
          'MenuItem--expandable': hasNestedItems
        })}
        {...elementProps}
      >
        {content}
      </Element>
      {hasNestedItems &&
        (isMdDown ? (
          <Collapse
            isOpened={isExpanded}
            theme={{
              collapse: 'MenuItem__collapse',
              content: 'MenuItem__body'
            }}
          >
            {nestedItems}
          </Collapse>
        ) : (
          nestedItems
        ))}
    </li>
  );
}

function Header(props: HeaderProps): JSX.Element {
  const {
    menuItems,
    menuItems2 = [],
    logoUrl,
    renderAdditionalContent,
    className
  } = props;

  const { isMdDown } = useViewPort();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileMenuAnimated, setIsMobileMenuAnimated] = useState(false);

  // disable page scroll
  useEffect(() => {
    window.document.body.style.overflowY = isMobileMenuOpen ? 'hidden' : '';
  }, [isMobileMenuOpen]);

  useEffect(() => {
    // close menu on page width change
    setIsMobileMenuOpen(false);
    // avoid unnecessary animation of moving to the right side (enabled css transition)
    if (isMdDown) {
      setTimeout(() => setIsMobileMenuAnimated(true), 300);
    } else {
      setIsMobileMenuAnimated(false);
    }
  }, [isMdDown]);

  return (
    <header
      className={cn('BaseHeader', className, {
        'BaseHeader--open': isMobileMenuOpen
      })}
    >
      <div
        aria-hidden="true"
        className="BaseHeader__overlay"
        onClick={() => setIsMobileMenuOpen(false)}
      />
      <div className="BaseHeader__container">
        <Link to="/" className="BaseHeader__logo">
          <img alt="Forta logo" src={logoUrl} height={20} />
        </Link>
        <Button
          size="lg"
          variant="tertiary"
          icon={isMobileMenuOpen ? CrossIcon : MenuIcon}
          className="BaseHeader__menu-button"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />
        <div
          className={cn('BaseHeader__menu', {
            'BaseHeader__menu--animated': isMobileMenuAnimated
          })}
        >
          <div className="BaseHeader__menu-container">
            <nav className="BaseHeader__navigation">
              <ul className="BaseHeader__nav-items">
                {menuItems.map((item) => (
                  <MenuItem
                    key={item.label}
                    item={item}
                    onClose={() => setIsMobileMenuOpen(false)}
                  />
                ))}
              </ul>
              {menuItems2.length > 0 && (
                <>
                  <div aria-hidden="true" className="BaseHeader__separator" />
                  <ul className="BaseHeader__nav-items">
                    {menuItems2.map((item) => (
                      <MenuItem
                        key={item.label}
                        item={item}
                        onClose={() => setIsMobileMenuOpen(false)}
                      />
                    ))}
                  </ul>
                </>
              )}
            </nav>
            {renderAdditionalContent && (
              <div className="BaseHeader__additional-menu">
                {renderAdditionalContent()}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
