export interface NetworkInfo {
  name: string;
  label: string;
  color: string;
  explorerUrl: string;
  explorerName: string;
  txExplorerUrl: string;
  chainId: number;
  rpcProviderUrl?: string;
  icon: string;
}

export enum Network {
  Mainnet = 1,
  Ropsten = 3,
  Rinkeby = 4,
  Goerli = 5,
  Kovan = 42,
  Xdai = 100,
  PolygonMainnet = 137,
  PolygonTestnet = 80001,
  Bsc = 56,
  Avax = 43114,
  Optimism = 10,
  Fantom = 250,
  Arbitrum = 42161,
  Localhost = 1337
}

export const NetworksInfo: { [key: string]: NetworkInfo } = {
  mainnet: {
    name: 'mainnet',
    label: 'Ethereum',
    color: '#0770fb',
    explorerUrl: 'https://etherscan.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/eth',
    explorerName: 'Etherscan',
    chainId: 1,
    icon: 'rsz_ethereum',
    rpcProviderUrl: 'https://rpc.ankr.com/eth'
  },
  ropsten: {
    name: 'ropsten',
    label: 'Ropsten',
    color: 'red',
    explorerUrl: 'https://ropsten.etherscan.io',
    txExplorerUrl: 'https://ropsten.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 3,
    icon: 'rsz_ethereum'
  },
  rinkeby: {
    name: 'rinkeby',
    label: 'Rinkeby',
    color: 'yellow',
    explorerUrl: 'https://rinkeby.etherscan.io',
    txExplorerUrl: 'https://rinkeby.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 4,
    icon: 'rsz_ethereum'
  },
  goerli: {
    name: 'goerli',
    label: 'Görli',
    color: 'green',
    explorerUrl: 'https://goerli.etherscan.io',
    txExplorerUrl: 'https://goerli.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 5,
    icon: 'rsz_ethereum'
  },
  kovan: {
    name: 'kovan',
    label: 'Kovan',
    color: 'blue',
    explorerUrl: 'https://kovan.etherscan.io',
    txExplorerUrl: 'https://kovan.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 42,
    icon: 'rsz_ethereum'
  },
  xdai: {
    name: 'xdai',
    label: 'XDai',
    color: 'green',
    explorerUrl: 'https://blockscout.com/xdai/mainnet',
    txExplorerUrl: 'https://blockscout.com/xdai/mainnet/tx',
    explorerName: 'Blockscout',
    chainId: 100,
    icon: 'rsz_xdai'
  },
  'polygon-mainnet': {
    name: 'polygon-mainnet',
    label: 'Polygon',
    color: '#8247e5',
    explorerUrl: 'https://polygonscan.com',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/polygon',
    explorerName: 'Polygonscan',
    chainId: 137,
    icon: 'rsz_polygon',
    rpcProviderUrl: 'https://polygon-rpc.com'
  },
  'polygon-testnet': {
    name: 'polygon-testnet',
    label: 'Polygon Testnet',
    color: '#8247e5',
    explorerUrl: 'https://mumbai.polygonscan.com',
    txExplorerUrl: 'https://mumbai.polygonscan.com/tx',
    explorerName: 'Polygonscan',
    chainId: 80001,
    icon: 'rsz_polygon',
    rpcProviderUrl: 'https://rpc.ankr.com/polygon_mumbai'
  },
  bsc: {
    name: 'bsc',
    label: 'BSC',
    color: '#f0b90b',
    explorerUrl: 'https://bscscan.com',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/bsc',
    explorerName: 'BSCScan',
    chainId: 56,
    icon: 'rsz_binance',
    rpcProviderUrl: 'https://rpc.ankr.com/bsc'
  },
  avax: {
    name: 'avax',
    label: 'Avalanche',
    color: '#e84142',
    explorerUrl: 'https://snowtrace.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/avax',
    explorerName: 'Snowtrace',
    chainId: 43114,
    icon: 'rsz_avalanche',
    rpcProviderUrl: 'https://rpc.ankr.com/avalanche'
  },
  optimism: {
    name: 'optimism',
    label: 'Optimism',
    color: '#FE0729',
    explorerUrl: 'https://optimistic.etherscan.io',
    txExplorerUrl: 'https://optimistic.etherscan.io/tx',
    explorerName: 'Etherscan',
    chainId: 10,
    icon: 'rsz_optimism',
    rpcProviderUrl: 'https://mainnet.optimism.io'
  },
  fantom: {
    name: 'fantom',
    label: 'Fantom',
    color: '#1a68fe',
    explorerUrl: 'https://ftmscan.com',
    txExplorerUrl: 'https://ftmscan.com/tx',
    explorerName: 'FMTScan',
    chainId: 250,
    icon: 'rsz_fantom',
    rpcProviderUrl: 'https://rpc.fantom.network'
  },
  arbitrum: {
    name: 'arbitrum',
    label: 'Arbitrum',
    color: '#349de0',
    explorerUrl: 'https://arbiscan.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/arbitrum',
    explorerName: 'Arbiscan',
    chainId: 42161,
    icon: 'rsz_arbitrum',
    rpcProviderUrl: 'https://rpc.ankr.com/arbitrum'
  },
  localhost: {
    name: 'localhost',
    label: 'Localhost',
    color: 'purple',
    explorerUrl: 'https://etherscan.io',
    txExplorerUrl: 'https://phalcon.blocksec.com/tx/eth',
    explorerName: 'etherscan',
    chainId: 1337,
    icon: 'rsz_ethereum'
  }
};

export const getNetworkByChainId = (chainId: number): NetworkInfo => {
  const networks = Object.values(NetworksInfo);
  return (
    networks.find((network) => network.chainId === chainId) ||
    NetworksInfo['localhost']
  );
};

export const getNetworkByName = (name: string): NetworkInfo => {
  const networks = Object.values(NetworksInfo);
  return (
    networks.find((network) => network.name === name) ||
    NetworksInfo['localhost']
  );
};

export const networksWhiteList: Network[] = [1, 137, 56, 43114, 250, 42161, 10];
export const networks = Object.values(NetworksInfo)
  .map((network) => ({ ...network, value: network.chainId }))
  .filter((network) => networksWhiteList.includes(network.chainId));

// const INFURA_KEY = '';

// export const NETWORK_URLS: {
//   [chainId in ChainId]: string
// } = {
//   [ChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.POLYGON_MAINNET]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
//   [ChainId.POLYGON_TESTNET]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
// };
