import queryString from 'query-string';
import { Severity } from './lib/apis/alertAPI';

function getRoute(
  path: string,
  params?: Record<string, string | number | (string | number)[]>
): string {
  return queryString.stringifyUrl(
    {
      url: path,
      query: params
    },
    {
      arrayFormat: 'none'
    }
  );
}

export const Routes = {
  alerts: {
    indexPath: '/alerts',
    index: (params: {
      text?: string;
      txHash?: string;
      severity?: Severity[];
      agents?: string[];
      addresses?: string[];
    }): string => getRoute('/alerts', params),

    details: {
      indexPath: '/alerts/:id',
      index: (params: { id: string }): string =>
        getRoute(`/alerts/${params.id}`)
    }
  },

  bots: {
    index: (params: { search?: string }): string =>
      queryString.stringifyUrl({
        url: '/',
        query: {
          search: params.search
        }
      })
  },
  bot: {
    detailsPath: `/bot/:id`,
    details: (params: { id: string }): string => `/bot/${params.id}`,
    documentationPath: `/bot/:id/documentation`,
    documentation: (params: { id: string }): string =>
      `/bot/${params.id}/documentation`,
    healthPath: `/bot/:id/health`,
    health: (params: { id: string }): string => `/bot/${params.id}/health`,
    stakePath: `/bot/:id/stake`,
    stake: (params: { id: string }): string => `/bot/${params.id}/stake`,
    shortenedDetailsPath: `/:shortenedPath`,
    shortenedDetails: (params: { shortenedPath: string }): string =>
      `/${params.shortenedPath}`,
    shortenedDocumentationPath: `/:shortenedPath/documentation`,
    shortenedDocumentation: (params: { shortenedPath: string }): string =>
      `/${params.shortenedPath}/documentation`,
    shortenedHealthPath: `/:shortenedPath/health`,
    shortenedHealth: (params: { shortenedPath: string }): string =>
      `/${params.shortenedPath}/health`,
    shortenedStakePath: `/:shortenedPath/stake`,
    shortenedStake: (params: { shortenedPath: string }): string =>
      `/${params.shortenedPath}/stake`
  },

  external: {
    discord: `https://discord.gg/KACdTEutQq`
  },
  flatPages: {
    legal: 'https://forta.org/legal/',
    privacyPolicy: 'https://forta.org/privacy/'
  },
  forms: {
    premiumFeeds:
      'https://docs.google.com/forms/d/e/1FAIpQLSer0RvntVyo6p09Eh6YS84-QyUgqo_NmGep_Ms6bT57xLc60A/viewform'
  }
};
