import React from 'react';
import cn from 'classnames';

import './Chip.scss';

type ChipProps = {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  variant?: 'default' | 'outline';
  active?: boolean;
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
};

function Chip(props: ChipProps): JSX.Element {
  const {
    startIcon,
    endIcon,
    variant = 'default',
    active,
    children,
    onClick,
    className
  } = props;

  return (
    <button
      className={cn('Chip', 'Chip--' + variant, className, {
        ['Chip--active']: active
      })}
      onClick={onClick}
    >
      {startIcon && <div className="Chip__icon">{startIcon}</div>}
      {children}
      {endIcon && <div className="Chip__icon">{endIcon}</div>}
    </button>
  );
}

type ChipContainerProps = {
  children: React.ReactNode;
  alignment: 'row' | 'column';
  className?: string;
};

function ChipContainer(props: ChipContainerProps): JSX.Element {
  const { children, className, alignment } = props;

  return (
    <div
      className={cn('ChipContainer', 'ChipContainer--' + alignment, className)}
    >
      {children}
    </div>
  );
}

export { ChipContainer };

export default Chip;
